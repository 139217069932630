import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import axios from 'axios';
import Cookies from 'js-cookie';

const StatsCard = ({
    title = "Total Trades",
    value = "$284,834.00",
    change = "18.6",
    timeframe = "from last month",
    bgColor = "bg-teal-400",
    textColor = "text-black",
    onClick = () => { },
    isActive = false,
    hasFilter = false
}) => {

    // render
    return (
        <div
            className={`${bgColor} flex justify-between items-center md:block rounded-xl p-4 relative cursor-pointer group transition-all duration-300 hover:shadow-lg`}
            // onClick={onClick}
        >
            <div className='flex flex-col gap-1 md:gap-0'>
                {/* Title */}
                <h3 className={`text-white text-md mb-2 md:mb-1 font-normal relative`}>
                    {title}

                    {/* {
                        hasFilter &&
                        <select
                            value={selectedFitlerValue}
                            onChange={(e) => handleFilterChange(e.target.value)}
                            className='bg-[#212C45] bg-opacity-50 outline-none text-white text-xs p-2 px-4 rounded-xl 
                            border-px border-[#212C45] md:absolute right-0'>
                            <option value="recent" selected>Recent</option>
                            <option value="today">Today</option>
                            <option value="1 week">This week</option>
                            <option value="1 month">This month</option>
                        </select>
                    } */}
                </h3>

                {/* Value */}
                <div className={`${textColor} text-xl font-medium mb-4`}>
                    {value || 0}
                </div>
            </div>


            {/* Change indicator */}
            <div className="inline-flex items-center bg-white rounded-full px-4 py-2 space-x-2 h-fit">
                <span className={`${Number(change) > 0 ? 'text-green-500' : 'text-red-500'} font-medium`}>
                    {change + '%'}
                </span>
                <span className="text-gray-500">
                    {timeframe}
                </span>
            </div>

            {/* Arrow button */}
            {isActive && <div className="hidden md:block absolute -right-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 transition-opacity duration-300">
                <ChevronRight className="w-5 h-5 text-gray-600" />
            </div>}
        </div>
    );
};

export default StatsCard