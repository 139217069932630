import React, { useState } from 'react';
import havokIcon from '../assets/images/havok.svg'
import {
    Bell,
    Mail,
    HelpCircle,
    X,
    Menu,
} from 'lucide-react';
import { Link } from 'react-router-dom';

const SidebarMobile = ({ name, email, role }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle sidebar visibility
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='md:hidden'>
            {/* Menu Button */}
            <button onClick={toggleSidebar} className="p-2 text-[#1e1e1e] bg-[#33CCCC] rounded-lg">
                <Menu />
            </button>

            {/* Sidebar */}
            <div
                className={`fixed z-20 top-0 right-0 w-full h-full bg-[#1E2946] transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
            >
                <img src={havokIcon} alt='havokIcon' className='absolute left-4 top-4' />
                {/* Close Button */}
                <button onClick={toggleSidebar} className="p-2 absolute top-4 right-4 text-white">
                    <X size={24} />
                </button>

                {/* Sidebar Content */}
                <div className="p-6 text-white pt-[70px]">
                    {/* User Profile */}
                    <div className="flex items-center mb-6">
                        <div className="bg-[#1BC5BD] rounded-full h-12 w-12 flex items-center justify-center text-xl font-semibold">
                            {name?.charAt(0)}
                        </div>
                        <div className="ml-4">
                            <p className="font-semibold">{name}</p>
                            <p className="text-gray-400 text-sm">{email}</p>
                        </div>
                    </div>

                    {/* Notifications, Messages, and Support */}
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <div className="relative flex items-center justify-center bg-gray-700 rounded-full h-10 w-10">
                                <Bell size={20} />
                                <span className="absolute top-0 right-0 bg-red-500 rounded-full text-xs px-1">2</span>
                            </div>
                            <span className="ml-4 text-gray-300">Notification</span>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center bg-gray-700 rounded-full h-10 w-10">
                                <Mail size={20} />
                            </div>
                            <span className="ml-4 text-gray-300">Messages</span>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center bg-gray-700 rounded-full h-10 w-10">
                                <HelpCircle size={20} />
                            </div>
                            <span className="ml-4 text-gray-300">Support</span>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="my-6 border-gray-600" />

                    {/* Navigation Links */}
                    <div className="space-y-4">
                        <Link to={role === 'user' ? '/user/home' : "/admin/home"} className="text-gray-300 block" onClick={toggleSidebar}>
                            Dashboard
                        </Link>
                        <Link to="/profile" className="text-gray-300 block" onClick={toggleSidebar}>
                            Profile
                        </Link>
                        <Link to="/news" className="text-gray-300 block" onClick={toggleSidebar}>
                            News
                        </Link>
                        <Link to="/market" className="text-gray-300 block" onClick={toggleSidebar}>
                            Market
                        </Link>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50"
                    onClick={toggleSidebar}
                ></div>
            )}
        </div>
    );
};

export default SidebarMobile;
