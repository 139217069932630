import React, { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'


function WalletActivity({ loading = false, walletData = [], initialPage = 1 }) {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const recordsPerPage = 8;
    const totalPages = Math.ceil(walletData.length / recordsPerPage);

    const currentData = walletData?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        const buttons = [];

        if (currentPage > 1) {
            buttons.push(
                <button className="font-medium text-[#32CCCB]" key="prev" onClick={() => handlePageChange(currentPage - 1)}>
                    <ChevronLeft color="white" />
                </button>
            );
        }

        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, startPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    className={`font-medium text-white ${currentPage === i ? 'text-[#32CCCB]' : ''}`}
                    key={i}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        if (currentPage < totalPages) {
            buttons.push(
                <button className="font-medium text-[#32CCCB]" key="next" onClick={() => handlePageChange(currentPage + 1)}>
                    <ChevronRight color="white" />
                </button>
            );
        }

        return buttons;
    };

    return (
        <div className="bg-[#2C3957] rounded-2xl p-5 md:p-8 shadow-lg text-white md:h-[100%]">
            <div className="flex flex-col gap-5 md:gap-10 items-center md:items-start">
                <span className="text-2xl font-semibold">Wallet Activity</span>
                <div className="table-btn-container flex-row items-center">
                    <button className="AddUserButton w-fit">Recent Transactions</button>
                    <button
                        className="px-2 py-[10px] md:px-4 md:py-4 rounded-full font-medium w-fit text-xs md:text-lg"
                        style={{ background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)' }}
                    >
                        Upcoming Transactions
                    </button>
                </div>
            </div>
            <div className="mobileTable userDataTable w-full md:w-auto mt-10">
                <table className="table md:w-full">
                    {currentData.length > 0 && (
                        <thead>
                            <tr>
                                <th className="userDataTableHeader">P/L</th>
                                <th className="userDataTableHeader">Date & Time</th>
                                <th className="userDataTableHeader">Amount</th>
                                <th className="userDataTableHeader">Total balance</th>
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {loading ? (
                            <div className="skeleton">
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                            </div>
                        ) : currentData.length === 0 ? (
                            <div className="flex bg-transparent flex-col items-center justify-center w-full py-8 md:min-h-[500px]">
                                <div className="text-2xl md:text-4xl text-[#32CCCB] font-semibold">No History Available</div>
                                <p className="text-sm md:text-lg mt-2 text-gray-400">Your transaction history will appear here.</p>
                            </div>
                        ) : (
                            currentData.map((item, index) => (
                                <tr key={index} className="text-white items-center">
                                    <td data-label="P/L" className="text-white text-lg">
                                        {item.profitLoss[0] === '+' ? 'P' : 'L'}
                                    </td>
                                    <td data-label="updatedOn" className="text-white text-lg">
                                        {item.updatedOn.slice(11)} <br /> {item.updatedOn.slice(0, 10)}
                                    </td>
                                    <td data-label="Profit/Loss Value" className="text-lg text-white">
                                        {item.profitLoss}
                                    </td>
                                    <td data-label="Balance Amount" className="text-white text-lg">
                                        {item.balanceAmount}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {walletData.length > 0 && (
                <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                    <div>
                        <span className="paginationResultText">
                            Showing {(currentPage - 1) * recordsPerPage + 1} to{' '}
                            {Math.min(currentPage * recordsPerPage, walletData.length)} of {walletData.length} records
                        </span>
                    </div>
                    <div className="flexRow" style={{ gap: 16 }}>
                        {renderPagination()}
                    </div>
                </div>
            )}
        </div>
    );
}

export default WalletActivity;
