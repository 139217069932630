import React, { useState } from 'react';
import bg from '../../assets/images/loginBg.svg';
import logo from '../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import UserType from '../../Helpers/getUserType';
import { Loader } from 'lucide-react';

const LoginPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [otp, setOtp] = useState(false);
    const [facingProblem, setFacingProblem] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailContact, setEmailContact] = useState('')
    const [passwordContact, setPasswordContact] = useState('')
    const [phone, setPhone] = useState('')
    const [issue, setIssue] = useState('')

    // Validation function
    const validate = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!password && !forgotPassword && !resetPassword) {
            newErrors.password = 'Password is required';
        } else if (password.length < 6 && !forgotPassword && !resetPassword) {
            newErrors.password = 'Password must be at least 6 characters';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle login submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            let apiRequest = {
                "email": email,
                "password": password
            };
            axios.post(process.env.REACT_APP_BACKEND + 'login', apiRequest)
                .then((apiResponse) => {
                    let token = apiResponse.data.token;
                    Cookies.set('token', token);
                    Cookies.set('api_token', "4g42X5Wkupqhbfd4jWlGoMp");
                    let userType = UserType(token);
                    setLoading(false);
                    if (userType === 'ADMIN') {
                        navigate('/admin/home');
                    } else if (userType === 'USER') {
                        if (apiResponse.data?.isNewUser) {
                            setResetPassword(true);
                            setOtp(password)
                        }
                        else navigate('/user/home');
                    }
                })
                .catch((apiError) => {
                    setError(apiError.response?.data?.message);
                    setLoading(false);
                    if (apiError.response?.status === 401) {
                        navigate('/login');
                    }
                });
        }
    };

    // Handle forgot password submission
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const apiResponse = await axios.post(`${process.env.REACT_APP_BACKEND}sendResetPasswordMail?email=${email}`);
            setMessage('Password link sent to your email');
        } catch (apiError) {
            setError(apiError.response?.data?.message || 'Email not found');
            setMessage(apiError.response?.data?.message || 'Email not found');
            // if (apiError.response?.status === 401) {
            //     // navigate('/login');
            //     handleLoginState()
            // }
        } finally {
            setLoading(false);
        }
    };

    // Handle password reset submission
    const handleResetPassword = (e) => {
        e.preventDefault();

        if (newPassword?.trim() !== confirmPassword?.trim()) {
            setError('Passwords do not match')
            setMessage('')
            return
        }

        setLoading(true);

        let token = Cookies.get('token');
        let headers = {
            Authorization: `Bearer ${token}`
        };
        let apiRequest = {
            "isNewUser": "True",
            "oldPassword": password,
            "newPassword": confirmPassword
        };
        axios.put(process.env.REACT_APP_BACKEND + 'updatePassword', apiRequest, { headers })
            .then(() => {
                setLoading(false);
                setMessage("Password reset successfully. Please log in with your new password.");
                navigate('/login');
                setError('')
                setResetPassword(false)
                setForgotPassword(false)
                setFacingProblem(false)
            })
            .catch((apiError) => {
                setError(apiError.response?.data?.message);
                setMessage('')
                setLoading(false);
                if (apiError.response?.status === 401) {
                    navigate('/login');
                }
            });
    };

    const handleLoginState = () => {
        setForgotPassword(false)
        setResetPassword(false)
        setError('')
        setMessage('')
    }

    const handleFacingIssue = () => {
        setFacingProblem(true)
    }

    const handleSubmitContact = (e) => {
        e.preventDefault();
        handleLoginState()
    }

    return (
        <div className="flex md:items-center md:justify-center w-full h-screen bg-cover bg-center"
            style={{ backgroundImage: window.innerWidth > 1200 ? `url(${bg})` : 'none' }}
        >
            <div className="flex flex-col h-screen md:h-auto w-full items-center md:flex-row bg-opacity-90 md:p-10 rounded-lg shadow-lg bg-darkBlue">
                <div className="flex-1 md:w-2/4 md:flex justify-center mb-4 md:mx-auto hidden">
                    <img src={logo} alt="Havok Labs" className="w-[48rem] h-auto" />
                </div>
                {/* Mobile Logo */}
                <div className="md:hidden h-[30vh] bg-cover bg-center flex items-center justify-center w-full"
                    style={{ backgroundImage: `url(${bg})` }}>
                    <img src={logo} alt="Havok Labs" className="w-60 h-auto" />
                </div>
                <div className="md:flex-0 lg:w-2/4 w-full">
                    <div className='max-w-xl mx-auto bg-[#1E2944] rounded-t-3xl md:rounded-2xl shadow-lg h-[70vh] md:h-auto'>
                        <h2 className="p-8 text-white text-2xl font-semibold mb-8 border-b-2 border-b-[#152445]">
                            {resetPassword ? "Reset Password" : forgotPassword ? "Forgot Password" : "Login"}
                        </h2>
                        <div className='px-8 pb-8'>
                            {!forgotPassword && !resetPassword && !facingProblem && (
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div>
                                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Email Address</label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={`w-full p-3 rounded-full bg-[#26334E] text-white outline-none border ${errors.email ? 'border-red-500' : 'border-transparent'}`}
                                            placeholder="Enter Email"
                                        />
                                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                    </div>
                                    <div>
                                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Password</label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className={`w-full p-3 rounded-full bg-[#26334E] text-white outline-none border ${errors.password ? 'border-red-500' : 'border-transparent'}`}
                                            placeholder="Enter Password"
                                        />
                                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                                    </div>
                                    <div className="flex justify-end items-center text-[#D3D5DB]">
                                        <p className="cursor-pointer underline text-sm" onClick={() => setForgotPassword(true)}>Forgot Password</p>
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full py-3 rounded-full text-white bg-gradient-custom"
                                    >
                                        {loading ?
                                            <div className='flex items-center justify-center'>
                                                <Loader size={15} />
                                            </div>
                                            : 'Login'}
                                    </button>
                                    <div className='text-center'>
                                        <button type='button' onClick={handleFacingIssue} className='text-[#3CC8C8] font-normal text-md mt-5'>Facing any issue? Contact here</button>
                                    </div>
                                </form>
                            )}

                            {forgotPassword && !facingProblem && (
                                <form onSubmit={handleForgotPassword} className="space-y-6">
                                    <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Email Address</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`w-full p-3 rounded-full bg-[#26334E] text-white outline-none border ${errors.email ? 'border-red-500' : 'border-transparent'}`}
                                        placeholder="Enter Email"
                                    />
                                    {message && <p className='text-center text-white my-3'>{message}</p>}
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full py-3 rounded-full bg-gradient-custom text-white"
                                    >
                                        {loading ?
                                            <div className='flex items-center justify-center'>
                                                <Loader size={15} />
                                            </div>
                                            : 'Send Reset Email'}
                                    </button>
                                    <div className='text-center'>
                                        <button type='button' onClick={handleLoginState} className='text-[#3CC8C8] font-normal text-md mt-5'>Click here to login</button>
                                    </div>
                                </form>
                            )}

                            {resetPassword && !facingProblem && (
                                <form onSubmit={handleResetPassword} className="space-y-4">

                                    {/* OTP Field */}
                                    <label className="block text-[#D3D5DB] text-sm font-medium">OTP</label>
                                    <input
                                        type="text"
                                        value={otp} // Make sure `otp` state is defined and holds the OTP value
                                        readOnly
                                        className="mb-2 w-full p-3 rounded-full bg-[#26334E] text-white outline-none"
                                        placeholder="Enter OTP"
                                        required
                                    />

                                    {/* New Password Field */}
                                    <label className="block text-[#D3D5DB] text-sm font-medium">New Password</label>
                                    <input
                                        type="password"
                                        value={newPassword} // Make sure `newPassword` state is defined and holds the new password value
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="mb-2 w-full p-3 rounded-full bg-[#26334E] text-white outline-none"
                                        placeholder="Enter New Password"
                                    />

                                    <label className="block text-[#D3D5DB] text-sm font-medium mb-2">New Password</label>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="mb-2 w-full p-3 rounded-full bg-[#26334E] text-white outline-none"
                                        placeholder="Enter New Password"
                                    />
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="w-full py-3 rounded-full text-white bg-gradient-custom"
                                    >
                                        {loading ? 'Please wait ...' : 'Reset Password'}
                                    </button>
                                </form>
                            )}

                            {/* contact / Facing problem */}
                            {facingProblem && (
                                <form onSubmit={handleSubmitContact} className="space-y-6">
                                    {/* Email Address */}
                                    <div>
                                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Email Address</label>
                                        <input
                                            required
                                            type="email"
                                            value={emailContact}
                                            onChange={(e) => setEmailContact(e.target.value)}
                                            className={`w-full p-3 rounded-full bg-[#26334E] text-white outline-none border ${errors.email ? 'border-red-500' : 'border-transparent'}`}
                                            placeholder="Enter Email"
                                        />
                                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                    </div>


                                    {/* Phone Number */}
                                    <div>
                                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Phone Number</label>
                                        <input
                                            required
                                            type="tel"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent"
                                            placeholder="+1 000 000 000"
                                        />
                                    </div>

                                    {/* Issue */}
                                    <div>
                                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Issue</label>
                                        <textarea
                                            required
                                            value={issue}
                                            onChange={(e) => setIssue(e.target.value)}
                                            className="w-full p-3 rounded-lg bg-[#26334E] text-white outline-none border border-transparent"
                                            placeholder="Write your issue here"
                                            rows="4"
                                        />
                                    </div>

                                    {/* Buttons */}
                                    <div className="flex space-x-4">
                                        <button
                                            type="button"
                                            onClick={() => setFacingProblem(false)}
                                            className="flex-1 py-3 rounded-full text-white bg-[#152445]"
                                        >
                                            Login
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            style={{ background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)' }}
                                            className="flex-1 py-3 rounded-full text-white hover:opacity-90"
                                        >
                                            {loading ? (
                                                <div className="flex items-center justify-center">
                                                    <Loader size={15} />
                                                </div>
                                            ) : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            )}


                            {message && !forgotPassword && <p className="text-green-500 mt-4">{message}</p>}
                            {error && !forgotPassword && <p className="text-red-500 mt-4">{error}</p>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
