import React from 'react';

function BalanceCard({ title, value, previousBalance = null, days = null, trades = null, height = 100 }) {
    // if (!value && !trades && !days) return <></>

    let isPositive =  value >= 0 || days >= 0  || trades >= 0

    return (
        <div className="bg-[#2C3A58] rounded-2xl p-6 flex justify-between flex-col">
            <div className="count-label">{title}</div>
            <div className="value-container flex flex-col justify-end" style={{ height: height }}>
                <span className={`amount ${trades || days ? 'text-white' :
                    isPositive ? 'text-[#3CC8C8]' : 'text-[#FD3549]'}`}>
                    {trades > 0 ? trades : days ? days : '$' + value?.toLocaleString()}
                </span>
                {previousBalance && <p className='text-[#D4D8DE]'>Previous Balance ${previousBalance}</p>}
                {days && <span className='text-white font-medium'>days</span>}
            </div>
        </div>
    );
}

export default BalanceCard;
