import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="rounded-xl p-4 shadow-lg" style={{
                background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)'
            }}>
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                        <span className="text-white">Profit</span>
                        <span className="text-green-400">↑ 12%</span>
                    </div>
                    <div className="text-white">
                        Earnings: ${payload[0].value.toLocaleString()}
                    </div>
                </div>
            </div>
        );
    }
    return null;
};


const ProfitChart = ({ data, height = "h-[500px]" }) => {
    return (
        <div className={`${height} flex items-center`}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                >
                    {/* Grid lines */}
                    <svg>
                        <defs>
                            <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#5eead4" stopOpacity={1} />
                                <stop offset="100%" stopColor="#5eead4" stopOpacity={0.2} />
                            </linearGradient>
                        </defs>
                    </svg>

                    {/* Axes */}
                    <XAxis
                        dataKey="month"
                        axisLine={true}
                        tickLine={true}
                        tick={{ fill: '#94a3b8' }}
                    />
                    <YAxis
                        axisLine={true}
                        tickLine={true}
                        tick={{ fill: '#94a3b8' }}
                        domain={[0, 50000]}
                        ticks={[0, 10000, 20000, 30000, 40000, 50000]}
                        tickFormatter={(value) => `${value / 1000}K`}
                    />

                    {/* Custom Tooltip */}
                    <Tooltip
                        content={<CustomTooltip />}
                        cursor={false}
                    />

                    {/* Line */}
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#5eead4"
                        strokeWidth={5}
                        activeDot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProfitChart;