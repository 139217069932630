import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../../Styles/Dashboard.css';
import UserType from '../../Helpers/getUserType';
import ButtonLoader from '../../Helpers/buttonLoader';
import Switch from 'react-switch';
import { Edit, Trash2, Eye, Pencil, Search, ChevronRight, ChevronLeft, ChevronDown, ChevronUp, X, Ellipsis, Cross } from 'lucide-react';
import pencilIcon from '../../assets/images/pencil.svg'
import trashIcon from '../../assets/images/trash.svg'
import filterIcon from '../../assets/images/filter.svg'
import viewIcon from '../../assets/images/view.svg'
import Dropdown from '../../Components/DropDownCheckBoxes';
import { Close } from '@mui/icons-material';

const UsersList = ({ updateAnalyticsStat }) => {
	const [openUpdate, setOpenUpdate] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [balanceUpdate, setBalanceUpdate] = useState(false);
	const [currentUser, setCurrentUser] = useState({});
	const [currentIndex, setCurrentIndex] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectType, setSelectType] = useState('Profit');
	const [buttonLoader, setButtonLoader] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [recordsFiltered, setRecordsFiltered] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [showArchieved, setShowArchieved] = useState(false)
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [balance, setBalance] = useState(0);
	const [addFunds, setAddFunds] = useState('');

	const [searchValue, setSearchValue] = useState('');
	const navigate = useNavigate();

	const recordsPerPage = 10;

	// Monitor screen width
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);


	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const getUsers = (page = 1, searchValue = '') => {
		setLoading(true);
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		}
		axios.get(`https://backend.luxara.ai/api/v1/users?start=${(currentPage - 1) * recordsPerPage}&searchValue=${searchValue}`, { headers: headers })
			.then((apiResponse) => {
				setData(apiResponse.data.users);
				setRecordsFiltered(apiResponse.data.recordsFiltered);
				setLoading(false);
				updateAnalyticsStat(apiResponse.data)
			})
			.catch((apiError) => {
				setLoading(false);
				if (apiError.response.status === 401) {
					navigate('/login')
				}
			})
	}

	const onSearchChange = (event) => {
		setSearchValue(event.target.value);
		setCurrentPage(1); // Reset to page 1 when search changes
	};

	useEffect(() => {
		let token = Cookies.get('token');
		if (token) {
			let userType = UserType(token);
			if (userType === 'USER') navigate('/home');
			else if (userType !== 'ADMIN') navigate('/login')
		}
	}, [])

	useEffect(() => {
		getUsers(currentPage, searchValue);
	}, [currentPage])

	useEffect(() => {
		renderTableData();
	}, [data])

	const onDeleteAction = () => {
		// Create a shallow copy of the data array
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		};
		let apiRequest = {
			data: {
				userId: data[currentIndex]?.id
			},
			headers: headers
		};
		console.log(headers, apiRequest);
		axios.delete('https://backend.luxara.ai/api/v1/users', apiRequest)
			.then((apiResponse) => {

				// let tempdata = [...data];
				// // Remove the item at the specified index
				// tempdata.splice(currentIndex, 1);
				// // Update the state with the new array
				// setData(tempdata);
				getUsers(currentPage, searchValue);
				setOpenDelete(false);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	};


	const ViewDetails = (item) => {
		navigate(`/admin/users/${item.id}`);
		// setLoading(true)
		// setFirstName('');
		// setLastName('')
		// setEmail('')
		// setMobileNumber('')
		// setBalance('')
		// setOpenView(true);
		// console.log('item', item)
		// let token = Cookies.get('token');
		// let headers = {
		// 	Authorization: `Bearer ${token}`
		// }
		// axios.get(`https://backend.luxara.ai/api/v1/getUserProfile?id=${item.id}`, {headers:headers})
		// .then((apiResponse)=>{
		//     console.log('apiResponse$$$$', apiResponse.data);
		// 	setFirstName(apiResponse.data?.username);
		// 	setLastName(apiResponse.data?.username)
		// 	setEmail(apiResponse.data?.email)
		// 	setMobileNumber(apiResponse.data?.phoneNumber)
		// 	setBalance(apiResponse.data?.balance)
		// 	setLoading(false);
		// 	// setOpenView(true);
		// })
		// .catch((apiError)=>{
		// 	setLoading(false);
		//     console.log('apiError', apiError.message);
		//     // console.log('apiError', apiError.response);
		//     // console.log('apiError', apiError.response.status);
		//     // console.log('apiError', apiError);
		// 	if(apiError.response.status === 401){
		// 		navigate('/login')
		// 	}
		// })
	}

	const handleOpenTradeUpdate = (item, index) => {
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`,
			userId: data[index]?.id,
		};

		let newData = new FormData();
		newData.append("request", JSON.stringify({
			enableTrade: !item.enableTrade
		}));
		axios.post('https://backend.luxara.ai/api/v1/updateUserProfile', newData, { headers: headers })
			.then((apiResponse) => {
				let tempdata = [...data];
				// Remove the item at the specified index
				item.enableTrade = !item.enableTrade;
				tempdata[index] = item;
				// Update the state with the new array
				setData(tempdata);
			})
			.catch((apiError) => {
				console.log('apiError', apiError.message);
				if (apiError.response.status === 401) {
					navigate('/login');
				}
			});
	}

	const onUpdateBalance = (item, index) => {
		setOpenUpdate(true);
		setCurrentUser(item);
	}

	const onAddFunds = (item, index) => {
		setOpenAdd(true);
		setCurrentUser(item);
	}

	// function ActionButtons({ item, index, onUpdateAction, setOpenDelete, setCurrentIndex, setCurrentUser, ViewDetails }) {
	// 	return (
	// 		<div className='flex px-2 gap-3 items-center justify-around md:justify-center'>
	// 			{/* Update Button */}
	// 			<button onClick={() => { onUpdateAction(item, index) }} className="bg-[#37445E] h-10 w-10 rounded-full ps-3">
	// 				<img src={pencilIcon} alt='pencil' className='w-5' />
	// 			</button>

	// 			{/* Remove Button */}
	// 			<button onClick={() => { setOpenDelete(true); setCurrentIndex(index); setCurrentUser(item); }} className="bg-[#37445E] h-10 w-10 rounded-full ps-2.5">
	// 				<img src={trashIcon} alt='trashIcon' className='w-5' />
	// 			</button>

	// 			{/* View Details */}
	// 			<button style={{
	// 				background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
	// 			}} onClick={() => { ViewDetails(item) }} className="rounded-3xl px-3 py-2 text-white flex gap-1 justify-center items-center">
	// 				<img src={viewIcon} alt='viewIcon' className='w-5' /> <span className='text-white'>Preview</span>
	// 			</button>
	// 		</div>
	// 	);
	// }

	const ActionButtons = ({
		item,
		index,
		onUpdateAction,
		setOpenDelete,
		setCurrentIndex,
		setCurrentUser,
		ViewDetails
	}) => {
		const [isOpen, setIsOpen] = useState(false);
		const dropdownRef = useRef(null);

		// Close dropdown when clicking outside
		useEffect(() => {
			const handleClickOutside = (event) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			};

			document.addEventListener('mousedown', handleClickOutside);
			return () => document.removeEventListener('mousedown', handleClickOutside);
		}, []);

		return (
			<div className="relative flex px-2 gap-3 items-center md:justify-center" ref={dropdownRef} style={{
				justifyContent: isMobile ? 'space-around' : 'center'
			}}>
				{/* Dropdown Trigger Button */}
				{isMobile ?
					<button
						onClick={() => setIsOpen(!isOpen)}
						className='flex-1'
					>
						<Ellipsis />
					</button> :
					<button
						onClick={() => setIsOpen(!isOpen)}
						className="flex items-center gap-2 px-4 py-2 rounded-full text-white"
						style={{
							background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
						}}
					>
						Action
						{isOpen ? <ChevronUp className="h-4 w-4" /> :
							<ChevronDown className="h-4 w-4" />}
					</button>}

				{/* Dropdown Menu */}
				{isOpen && (
					<div className="absolute top-7 mt-2 w-48 sm:left-2 bg-[#2A3B59] rounded-lg shadow-lg py-1 z-10 text-white">
						<div className="flex justify-between items-center px-4 py-2 border-b border-[#28274D]">
							<span className="text-sm">Action</span>
							<button
								onClick={() => setIsOpen(false)}
								className="text-gray-400 hover:text-white"
							>
								<X />
							</button>
						</div>
						<div className='w-[100%] flex flex-col items-center justify-center'>
							{/* Update Balance Option */}
							<button
								onClick={() => {
									onUpdateBalance(item, index); // Make sure to define this function
									setIsOpen(false);
								}}
								className="w-[80%] text-white mt-2 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
							>
								Update Balance
							</button>

							{/* Add Funds Option */}
							<button
								onClick={() => {
									onAddFunds(item, index); // Make sure to define this function
									setIsOpen(false);
								}}
								className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

							>
								Add Funds
							</button>

							{/* View Option */}
							<button
								onClick={() => {
									ViewDetails(item);
									setIsOpen(false);
								}}
								className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

							>
								View
							</button>

							{/* Delete Option */}
							<button
								onClick={() => {
									setOpenDelete(true);
									setCurrentIndex(index);
									setCurrentUser(item);
									setIsOpen(false);
								}}
								className="w-[80%] text-red-500 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
							>
								Delete
							</button>
						</div>
					</div>
				)}


			</div>
		);
	};
	console.log(data)
	const renderTableData = () => {
		// let renderData = searchValue ? searchUsers(searchValue) :
		// 				currentPage ? data.slice(((currentPage-1)*5), (currentPage*5)) : data;

		return (
			isMobile ? (
				// Mobile view
				data?.map((item, index) => (
					<div key={index} className="bg-[#1E2946] rounded-lg p-4 mb-4 text-white">
						<div className="flex justify-between items-center mb-2">
							<div className="text-lg font-semibold">{item.userName}</div>
							<div className="cursor-pointer">
								<ActionButtons
									item={item}
									index={index}
									onUpdateAction={onUpdateAction}
									setOpenDelete={setOpenDelete}
									setCurrentIndex={setCurrentIndex}
									setCurrentUser={setCurrentUser}
									ViewDetails={ViewDetails}
								/>
							</div>
						</div>
						<div className="flex justify-between items-center mb-2">
							<span>Enable Trading</span>
							<Switch
								onChange={() => handleOpenTradeUpdate(item, index)}
								checked={item.enableTrade}
							/>
						</div>
						<div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
							<span className="text-[#5AB1A4]">Balance</span>
							<span>${item.balance}</span>
						</div>
						<div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
							<span className="text-[#5AB1A4]">Profit / Loss</span>
							<span className={item.profitLossType === "Loss" ? 'text-[#FD3549]' : 'text-[#00B087]'}>
								{item?.profitLossAmount}
							</span>
						</div>
						<div className="flex justify-between mb-2 border-b border-b-[#152445] py-2">
							<span className="text-[#5AB1A4]">Cycle</span>
							<span>{item.subscriptionPeriod ? item.subscriptionPeriod : 0} days</span>
						</div>
						<div className="flex justify-between py-2">
							<span className="text-[#5AB1A4]">Open Trades</span>
							<span>{item.openTrades ? item.openTrades : 0}</span>
						</div>
						<div className="mt-3">

						</div>
					</div>
				))
			)
				: <div className='mobileTable userDataTable'>
					<table className="table">
						<thead>
							<tr>
								<th className='userDataTableHeader text-start'>Name</th>
								<th className='userDataTableHeader'>Balance</th>
								<th className='userDataTableHeader'>Profit/ Loss</th>
								<th className='userDataTableHeader'>Cycle</th>
								<th className='userDataTableHeader'>
									<div className='text-center'>
										Open Trades
									</div>
								</th>
								<th className='userDataTableHeader text-center'>
									<div className='text-center'>
										Enable Trading
									</div>
								</th>
								<th className='userDataTableHeader text-end'>
									<div className='text-center'>
										Action
									</div>
								</th>
								{/* <th className='userDataTableHeader'>Remove</th> */}
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<div className="skeleton">
									<div className="skeleton-line"></div>
									<div className="skeleton-line"></div>
									<div className="skeleton-line"></div>
									<div className="skeleton-line"></div>
									<div className="skeleton-line"></div>
									<div className="skeleton-line"></div><div className="skeleton-line"></div>
									<div className="skeleton-line"></div>
								</div>
							) : (
								data.map((item, index) => (
									<tr key={index} className='text-white'> {/* Make sure to add a key here */}
										<td onClick={() => { ViewDetails(item) }}
											data-label="Name" className="md:text-white cursor-pointer text-start text-lg">{item.userName}
										</td>
										<td data-label="Balance" className="text-white text-lg">{item.balance}</td>
										<td data-label="Profit/ Loss" className={`text-lg ${item.profitLossType === "Loss" ? 'text-[#FD3549]' : 'text-[#00B087]'}`}>
											{item?.profitLossAmount}
										</td>
										<td data-label="Cycle" className="text-white text-lg">
											{item.subscriptionPeriod ? item.subscriptionPeriod : 0} days
										</td>
										<td data-label="Enable Trading" className="text-white text-lg">
											<div className='text-center'>
												{item.openTrades ? item.openTrades : 0}
											</div>
										</td>
										<td data-label="Enable Trading">
											<div className='text-center'>
												<Switch
													onChange={() => handleOpenTradeUpdate(item, index)}
													checked={item.enableTrade}
													className='text-center'
												/>
											</div>
										</td>
										<td data-label="Action">
											<ActionButtons
												item={item}
												index={index}
												onUpdateAction={onUpdateAction}
												setOpenDelete={setOpenDelete}
												setCurrentIndex={setCurrentIndex}
												setCurrentUser={setCurrentUser}
												ViewDetails={ViewDetails}
											/>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
		);
	}

	// Calculate the total number of pages
	const totalPages = Math.ceil(recordsFiltered / recordsPerPage);

	// Function to handle page navigation
	const handlePageChange = (page) => {
		setCurrentPage(page);
	}

	// Render pagination buttons
	const renderPagination = () => {
		const buttons = [];

		// Add Previous button if currentPage is greater than 1
		if (currentPage > 1) {
			buttons.push(
				<button className='font-medium text-[#32CCCB]' key="prev" onClick={() => handlePageChange(currentPage - 1)}>
					<ChevronLeft color='white' />
				</button>
			);
		}

		// Calculate the starting page number for pagination
		let startPage = 1;
		if (startPage <= 0) {
			startPage = 1;
		}

		// Calculate the ending page number for pagination
		let endPage = startPage + 3;
		if (endPage > totalPages) {
			endPage = totalPages;
		}

		// Render pagination buttons for each page
		for (let i = startPage; i <= endPage; i++) {
			buttons.push(
				<button className={`font-medium text-white ${currentPage === i ? 'text-[#32CCCB]' : ''}`} key={i} onClick={() => handlePageChange(i)}>{i}</button>
			);
		}

		// Add Next button if currentPage is less than totalPages
		if (currentPage < totalPages) {
			buttons.push(
				<button className='font-medium text-[#32CCCB]' key="next" onClick={() => handlePageChange(currentPage + 1)}>
					<ChevronRight color='white' />
				</button>
			);
		}

		return buttons;
	}

	const onUpdateAction = (item, index) => {
		setCurrentUser(item);
		setSelectType(item.profitLossType)
		setCurrentIndex(index);
		setOpenUpdate(true);
		console.log('$$$$$$$$$$$$$$', currentUser);
	}

	const onCloseUpdate = () => {
		setOpenUpdate(false)
	}

	const onCloseAdd = () => {
		setOpenAdd(false);
	}

	const onCloseView = () => {
		setOpenView(false)
	}

	const onCloseDelete = () => {
		setOpenDelete(false)
	}

	const handleDropdownChange = (event) => {
		setSelectType(event.target.value);
	};

	const save = () => {
		setButtonLoader(true)
		let token = Cookies.get('token');
		let headers = {
			Authorization: `Bearer ${token}`
		}
		console.log(currentUser);
		let apiRequest = {
			"userId": currentUser.id,
			"subscriptionPeriod": currentUser.subscriptionPeriod,
			"openTrades": currentUser.openTrades
		}
		if (balanceUpdate) {
			apiRequest.type = selectType;
			apiRequest.amount = currentUser.profitLossAmount.replace(/[^0-9.]+/g, "");
		}
		// console.log('apiRequest', apiRequest)
		axios.post('https://backend.luxara.ai/api/v1/updateBalance', apiRequest, { headers: headers })
			.then((apiResponse) => {
				// console.log('apiResponse', apiResponse.data);
				let temp = [...data];
				currentUser.profitLossType = selectType;
				if (selectType === 'Profit') {
					currentUser.balance = Number(currentUser.balance) + Number(currentUser.profitLossAmount);
				} else if (selectType === 'Loss') {
					currentUser.balance = Number(currentUser.balance) - Number(currentUser.profitLossAmount);
				}
				temp[currentIndex] = currentUser;
				// console.log('currentUser@@@@@@@@', currentUser)
				// setData(temp);
				getUsers();
				setSelectType('')
				setButtonLoader(false);
				setOpenUpdate(false);
			})
			.catch((apiError) => {
				setLoading(false);
				setOpenUpdate(false);
				console.log('apiError', apiError.messsage);
				setButtonLoader(false);
				if (apiError.response.status == 401) {
					navigate('/login')
				}
			})
	}

	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			...(isMobile ? { minWidth: '250px' } : { minWidth: '300px' }),
			padding: '0px',
			borderRadius: '8px',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
			backgroundColor: '#212F4C',
			border: '0px #28274D solid',
			minWidth: '400px'
		},
	};

	const AddUser = () => {
		navigate('/adduser')
	}

	const DeletePopUp = () => {
		return (
			<Modal
				isOpen={openDelete}
				onRequestClose={onCloseDelete}
				contentLabel="Delete Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}>
					<div>
						<span className='fieldName'>Delete User</span>
					</div>
					<span className='absolute right-2 text-white' onClick={() => setOpenDelete(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '24px' }}>
					<span className='fieldName'>Are you sure you want to delete user?</span>
				</div>
				<div className='flexRow' style={{ marginTop: 15, justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={onDeleteAction}
								className='deleteButton'
							>
								Delete
							</button>
					}
				</div>
			</Modal>
		)
	}

	const AddPopUp = () => {
		return (
			<Modal
				isOpen={openAdd}
				onRequestClose={onCloseAdd}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px' }}>
					<span className='username'>Add Fund</span>
					<span className='absolute right-2 text-white' onClick={() => setOpenAdd(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', borderTop: '1px solid #28274D', margin: '10px 0' }} />
				<div className='input-container mt-8 mb-8 p-6'>
					<label className="block text-sm text-[#FFF] mt-1">Add Funds</label>
					<div className="flex relative">
						<select className="adduserInput dropdown text-right" disabled>
							<option>$</option>
						</select>
						<input type="tel" value={addFunds} placeholder="5000" className="adduserInput phone"
							onChange={(e) => {
								setAddFunds(e.target.value);
							}} />
					</div>
				</div>
				<div className='flexRow alignCenter' style={{ padding: '24px' }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={save}
								className='save'
							>
								+ Add Funds
							</button>
					}
				</div>
			</Modal>
		)
	}

	const UpdatePopUp = () => {
		return (
			<Modal
				isOpen={openUpdate}
				onRequestClose={onCloseUpdate}
				contentLabel="Example Modal"
				style={customStyles}
			>
				<div className='flexRow relative' style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '16px', paddingBottom: '16px', borderBottom: '1px solid #28274D' }}>
					<span className='username'>Update Balance</span>
					<span className='absolute right-2 text-white' onClick={() => setOpenUpdate(false)}><Close /></span>
				</div>
				<hr style={{ border: 'none', margin: '10px 0' }} />
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Profit/ Loss</span>
					<select value={selectType}
						className='dropDownInput'
						onChange={handleDropdownChange}>
						<option value="">Select</option>
						<option value="Profit">Profit</option>
						<option value="Loss">Loss</option>
					</select>
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Amount</span>
					<input
						type="text"
						className='numberInput'
						value={currentUser.profitLossAmount ? currentUser.profitLossAmount.replace(/[^0-9.]+/g, "") : "0"}
						onChange={(e) => {
							let inputValue = e.target.value;

							inputValue = inputValue.replace(/^0+(?=\d)/, '');

							if (currentUser.profitLossAmount && inputValue !== currentUser.profitLossAmount.replace(/[^0-9.]+/g, "")) {
								setBalanceUpdate(true);
							}

							// Allow only numbers, decimal points, and optional leading '-' sign
							if (/^\d*\.?\d*$/.test(inputValue)) {
								let temp = {
									id: currentUser.id,
									userName: currentUser.userName,
									balance: currentUser.balance,
									profitLossAmount: inputValue,
									profitLossType: currentUser.profitLossType,
									openTrades: currentUser.openTrades,
									subscriptionPeriod: currentUser.subscriptionPeriod
								};
								setCurrentUser(temp);
							}
						}}
					></input>
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Cycle days</span>
					<input
						type='number'
						className='numberInput'
						value={currentUser.subscriptionPeriod ? currentUser.subscriptionPeriod : 0}
						onChange={(e) => {
							let inputValue = e.target.value;
							let temp = {
								id: currentUser.id,
								userName: currentUser.userName,
								balance: currentUser.balance,
								profitLossAmount: currentUser.profitLossAmount,
								profitLossType: currentUser.profitLossType,
								openTrades: currentUser.openTrades,
								subscriptionPeriod: inputValue
							};
							setCurrentUser(temp);
						}}
					></input>
				</div>
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
					<span className='fieldName'>Open Trades</span>
					<input
						type='number'
						className='numberInput'
						value={currentUser.openTrades ? currentUser.openTrades : 0}
						onChange={(e) => {
							let inputValue = e.target.value;
							let temp = {
								id: currentUser.id,
								userName: currentUser.userName,
								balance: currentUser.balance,
								profitLossAmount: currentUser.profitLossAmount,
								profitLossType: currentUser.profitLossType,
								openTrades: inputValue,
								subscriptionPeriod: currentUser.subscriptionPeriod
							};
							console.log(inputValue);
							setCurrentUser(temp);
						}}
					></input>
				</div>
				<div className='flexRow alignCenter' style={{ marginTop: 15, marginBottom: 15 }}>
					{
						buttonLoader ?
							<div className='save flexRow alignCenter rounded-xl'>
								<ButtonLoader />
							</div>
							:
							<button
								onClick={save}
								className='save rounded-xl'
							>
								Save
							</button>
					}
				</div>
			</Modal>
		)
	}


	const renderPersonalData = () => {
		return (
			<>
				{
					loading ? (
						<div className="skeleton profileCard flexCol">
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
							<div className="skeleton-line"></div>
						</div>
					) :
						<div className="profileCard flexCol" style={{ width: 'auto' }}>
							<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
								<span className='username'>Yeswanth</span>
								<button
									style={{ backgroundColor: '#E31892', color: '#FFF' }}
									onClick={onCloseView}
								>X</button>
							</div>
							<hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">First Name</span>
								</div>
								{
									firstName ?
										<div>
											<span className="name">{firstName}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Last Name</span>
								</div>
								{
									lastName ?
										<div>
											<span className="name">{lastName}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Mobile Number</span>
								</div>
								{
									mobileNumber ?
										<div>
											<span className="name">{mobileNumber}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Email</span>
								</div>
								{
									email ?
										<div>
											<span className="name">{email}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							<div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight: 40 }}>
								<div>
									<span className="name">Balance</span>
								</div>
								{
									balance ?
										<div>
											<span className="name">{balance}</span>
										</div>
										:
										<div className="skeleton profileCard flexCol" style={{ paddingBottom: 0, paddingTop: 0 }}>
											<div className="skeleton-line"></div>
										</div>
								}
							</div>
							{/* <div className="flexRow" style={{ justifyContent: 'space-between', alignItems: 'center', minHeight:40 }}>
                        <div>
                            <span className="name">Password</span>
                        </div>
                        {renderField('password', 'Password')}
                    </div> */}
						</div>
				}
			</>
		)
	}


	const customStylesWithoutBackgroundAndPadding = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			...(isMobile ? { minWidth: '250px' } : { minWidth: '500px' }),
			borderRadius: '12px',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
			padding: 0
			// Exclude background color and padding
		},
	};

	const ViewDetailsPopUp = () => {
		return (
			<Modal
				isOpen={openView}
				onRequestClose={onCloseView}
				contentLabel="Example Modal"
				style={customStylesWithoutBackgroundAndPadding}
			>
				{
					renderPersonalData()
				}
			</Modal>
		)
	}

	const handleSearchKeyPress = (event) => {
		if (event.key === 'Enter') {
			console.log(event.key);
			setCurrentPage(1); // Reset to page 1 for new search results
			getUsers(1, searchValue); // Fetch users based on search value
		}
	};

	return (
		<div className='w-full bg-[#152445] md:bg-[#212F4C] rounded-2xl'>
			<div className='userTable rounded-2xl'>
				<div className='md:topdivs md:flex md:justify-between items-center'>
					<div className='flexCol' style={{ alignItems: 'flex-start' }}>
						<div className='allusers flex justify-center md:justify-start items-center w-full' >
							<span className='mx-auto md:mx-none'>{showArchieved ? 'Archived Users' : 'All Users'}</span>
						</div>
					</div>
					<div className='md:topfeatures flex justify-between gap-2 items-center'>
						<div className='md:hidden' style={{ width: '150px' }}>
							<button
								style={{
									background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
								}}
								onClick={AddUser}
								className='rounded-full sm:w-[150px] py-2 text-white text-center text-sm px-5'>
								+ Add User
							</button>
						</div>
						<div className='relative md:hidden'>
							<Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
							<input
								className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
								onKeyDown={handleSearchKeyPress}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Search'
							/>
						</div>
						<button
							className={`hidden md:block ${!showArchieved ? 'border-2 border-[#39455F] text-[#D2D4DA]' : 'bg-gradient-to-r from-[#7178FA] via-[#44AFD4] to-[#0AECA9] text-white'} 
							rounded-full py-2 px-4 min-w-[170px] text-center w-fit`} onClick={() => setShowArchieved(!showArchieved)}>
							{!showArchieved ? 'Archived Users' : 'All Users'}
						</button>
						<div className='relative hidden md:block'>
							<Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
							<input
								className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
								onKeyDown={handleSearchKeyPress}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Search'
							/>
						</div>
						<span className='md:hidden'>
							<img src={filterIcon} alt='filterIcon' className='h-12' />
						</span>
						<div style={{ width: '100%' }} className='hidden md:block'>
							<Dropdown />
						</div>
						<div style={{ width: '95%' }} className='hidden md:block'>
							<button
								style={{
									background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
								}}
								onClick={AddUser}
								className='rounded-full py-2 text-white text-center px-5 md:min-w-[185px]'>
								+ Add User
							</button>
						</div>
					</div>
				</div>

				{
					renderTableData()
				}
				<div className='flexRow' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
					<div>
						<span className='paginationResultText'>
							Showing {(currentPage - 1) * 10 + 1} to {currentPage * 10 > recordsFiltered ? recordsFiltered : currentPage * 10} of {recordsFiltered} users
						</span>
					</div>
					<div className='flexRow' style={{ gap: 16 }}>
						{
							renderPagination()
						}
					</div>
				</div>

			</div>
			{
				UpdatePopUp()
			}
			{
				DeletePopUp()
			}
			{
				ViewDetailsPopUp()
			}
			{
				AddPopUp()
			}
		</div>
	)
}

export default UsersList;