import React from 'react'
import { Link } from 'react-router-dom'

function NewsCard({ article, navigate }) {
    return (
        <div
            key={article?.id}
            onClick={() => { navigate('/news/' + article?.id) }}
            className="cursor-pointer bg-[#202F4C] rounded-2xl"
        >
            <div className="flex flex-col md:flex-row items-center p-4 gap-4 md:gap-10 bg-dark-blue rounded-lg shadow-lg">
                {/* Image Section */}
                <div className="w-full h-52 md:h-54 md:w-auto flex-shrink-0 mb-4 md:mb-0">
                    <img
                        src={article?.image}
                        alt={article?.title}
                        className="w-full h-full object-cover rounded-md"
                    />
                </div>

                {/* Text Section */}
                <div className="md:flex-1 min-w-0 flex flex-col gap-2">
                    <div className="flex items-center gap-2 text-gray-400 text-sm">
                        <span>By <span className='text-[#33CCCC]'>{article?.author || 'Author'}</span></span>
                        <span> | </span>
                        <span>{article?.timeAgo}</span>
                    </div>

                    <h2 className="text-lg md:text-xl font-semibold text-white">
                        {article?.title}
                    </h2>

                    <p className="text-gray-400 text-sm md:text-md line-clamp-2">
                        {article?.description}
                    </p>

                    <Link to={'/news/' + article?.id} className="text-[#33CCCC] text-sm mt-2">
                        Read more...
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NewsCard