import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import EmailIcon from '@mui/icons-material/Email';
// import IconButton from '@mui/material/IconButton';
// import InputAdornment from '@mui/material/InputAdornment';
// import { Visibility, VisibilityOff, Lock } from '@mui/icons-material';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import '../Styles/Login.css';
import UserType from '../Helpers/getUserType';
import ButtonLoader from '../Helpers/buttonLoader';

const Login = () => {
    const navigate = useNavigate();
    const [isIssue, setIsIssue] = useState(false);
    // const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    // const [balance, setBalance] = useState('');
    const [loading, setLoading] = useState(false);
    const [description, setDescription ] = useState('');
    const [initialLogin, setInitialLogin] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
   

    useEffect(() => {
        // const tokenExists = Cookies.get('token') !== undefined;
        // if (tokenExists) {
        //   navigate('/');
        // }
      }, []);
    
      const handleUpdatePassword = () => {
        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            Authorization : `Bearer ${token}`
        }
        let apiRequest = {
            "isNewUser": "True",
            "oldPassword" : password,
            "newPassword" : confirmPassword
        }
        console.log('apiRequest', apiRequest);
        axios.put('https://backend.luxara.ai/api/v1/updatePassword', apiRequest, {headers: headers})
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            setLoading(false);
            navigate('/')
            // let token = apiResponse.data.token;
            // Cookies.set('token', token);
            // let userType = UserType(token);
            // setLoading(false);
            // if(userType === 'ADMIN'){
            //     navigate('/admin/home')
            // } else if (userType === 'USER') {
            //     if(apiResponse.data?.isNewUser) setInitialLogin(true);
            //     else navigate('/')
            // }
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError', apiError.messsage);
            setError(apiError.messsage);
            setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
    };

    
    const validateFields = () => {
        // console.log('$$$$$$4check', !password ||
        // !newPassword || 
        // !confirmPassword || 
        // newPassword !== confirmPassword)
        return (
            !password ||
            !newPassword || 
            !confirmPassword || 
            newPassword !== confirmPassword
        )
    }

    const buttonStyle = {
        backgroundColor: '#7636CE',
        color: 'white',
        borderRadius: '16px', // rounded corners
        '&:hover': {
          backgroundColor: '#5b2d9e', // darker shade on hover
        },
      };
    const textfieldStyle = {
        borderRadius: '4px',
        border:'none',
        backgroundColor: '#FFFFFF'
    }

    const handleResetPassword = () => {
        setLoading(true);
        axios.post(`https://backend.luxara.ai/api/v1/sendResetPasswordMail?email=${email}`)
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            setMessage(apiResponse.data?.message)
            setLoading(false);
            
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError$$$$$$$$$$$$$check', apiError.response.data.message);
            setError(apiError.response?.data?.message)
            setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
    }


    const handleLogin = () => {
        setLoading(true);
        let apiRequest = {
            "email" : email,
            "password" : password
        }
        console.log('apiRequest', apiRequest);
        axios.post('https://backend.luxara.ai/api/v1/login', apiRequest)
        .then((apiResponse) => {
            console.log('apiResponse', apiResponse.data);
            let token = apiResponse.data.token;
            Cookies.set('token', token);
            Cookies.set('api_token', "4g42X5Wkupqhbfd4jWlGoMp")
            let userType = UserType(token);
            setLoading(false);
            if(userType === 'ADMIN'){
                navigate('/admin/home')
            } else if (userType === 'USER') {
                if(apiResponse.data?.isNewUser) setInitialLogin(true);
                else navigate('/user/home')
            }
        })
        .catch((apiError)=>{
            console.log('apiError', apiError)
            console.log('apiError$$$$$$$$$$$$$check', apiError.response.data.message);
            setError(apiError.response?.data?.message)
            setLoading(false);
			if(apiError.response.status === 401){
				navigate('/login')
			}
        })
    };

    // const handleSignUp = () => {
    //     setLoading(true);
    //     let apiRequest = {
    //         "email" : email,
    //         "password" : password,
    //         "username" : name,
    //         "phoneNumber" : phoneNumber,
    //         "firstName" : firstName,
    //         "lastName" : lastName,
    //         "balance" : Number(balance)
    //     }
    //     console.log('apiRequest', apiRequest);
    //     axios.post('https://backend.luxara.ai/api/v1/signup', apiRequest)
    //     .then((apiResponse)=>{
    //         console.log('apiResponse', apiResponse.data);
    //         let token = apiResponse.data.token;
    //         Cookies.set('token', token);
    //         let userType = UserType(token);
    //         setLoading(false);
    //         if(userType === 'ADMIN'){
    //             navigate('/admin/home')
    //         } else if (userType === 'USER') {
    //             navigate('/')
    //         }
    //     })
    //     .catch((apiError)=>{
    //         console.log('apiError', apiError.messsage);
    //         setLoading(false);
	// 		if(apiError.response.status == 401){
	// 			navigate('/login')
	// 		}
    //     })
    // };
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='mainLogin'>
            <div className='leftContainer'>
                <img className='fullImage' src={require('../assets/logoBanner.svg').default} alt="Crypto Banner" />
            </div>
            <div className='loginContent'>
                
                {
                    initialLogin 
                    ? 
                    <div className='loginContainer'>
                        <div style={{width:'100%', position : 'relative'}}>
                            <input
                              type={showPassword ? 'text' : 'password'}  
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)}
                                className="UsenameField" 
                                placeholder="New password" 
                                />
                            <button 
                                type="button" 
                                onClick={toggleShowPassword}
                                className = "password-toggle-btn"
                                >
                                {showPassword ? 'Hide' : 'Show'}  
                            </button>
                        </div>
                        <div style={{width:'100%'}}>
                            <input type="password"  
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="UsenameField" placeholder="New password" />
                        </div>
                        <div style={{width:'100%'}}>
                            <input type="password"  
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="UsenameField" placeholder="Confirm password" />
                        </div>
                        <div className='flexRow alignCenter'>
                            {
                                loading ? 
                                <div className='LoginButton flexRow alignCenter'>
                                    <ButtonLoader />
                                </div>
                                :
                                <button
                                // disabled={validateFields}
                                // style={{backgroundColor: (validateFields) ? 'gray' : 'red'}}
                                onClick={handleUpdatePassword}
                                className='LoginButton'>Update password & Login</button>
                            }
                        </div>
                    </div>
                    :
                    resetPassword ? 
                    <div className='loginContainer'>
                        <div style={{width:'100%', position : 'relative'}}>
                        <div style={{display:'flex',flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                            <div>
                                <span className='hello'>
                                    Forgot Password ?
                                </span>
                            </div>
                        </div>
                        <div style={{height: "20px"}} />
                        <input type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                className="UsenameField" placeholder="✉️ Email Address" 
                                style= {{paddingLeft: '30px' }}/>
                        </div>
                        <div style={{display:'flex',flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                            <div>
                                <span style={{fontSize: '15px', color: 'white'}}>
                                    { message !== '' ? message : 'A password reset link will be sent to your registered email address' }
                                </span>
                            </div>
                            </div>
                        <div className='flexRow alignCenter'>
                            {
                                loading ? 
                                <div className='LoginButton flexRow alignCenter'>
                                    <ButtonLoader />
                                </div>
                                :
                                <button
                                // disabled={validateFields}
                                // style={{backgroundColor: (validateFields) ? 'gray' : 'red'}}
                                onClick={handleResetPassword}
                                className='LoginButton'>Send Password Reset Link</button>
                            }
                        </div>
                        <div>
                            <span className='loginNote'>
                                <button 
                                    onClick={() => setResetPassword(!resetPassword)}
                                    style={{color:'#7636CE', background: 'none', border: 'none', cursor: 'pointer', textDecoration:'underline'}}
                                >
                                    {!resetPassword ? 'Forgot password ? Click here' : 'Click here to Login'}
                                </button>
                            </span>
                        </div>
                    </div>
                    : 
                    <div className='loginContainer'>
                        <div className='mobileLogo'>
                            <img className='fullImage' src={require('../assets/logoBanner.svg').default} alt="Crypto Banner" />
                        </div>
                        <div style={{display:'flex',flexDirection:'column', alignItems:'flex-start', width:'100%'}}>
                            <div>
                                <span className='hello'>
                                    Hello Again !
                                </span>
                            </div>
                            <div>
                                <span className='greet'>
                                    Welcome Back
                                </span>
                            </div>
                        </div>
                        <div style={{width:'100%', position :'relative'}}>
                            <span style = {{position : 'absolute',left:'10px',top:'50%',transform :'translateY(-50%)'}}>
                                </span>
                            <input type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                className="UsenameField" placeholder="✉️ Email Address" 
                                style= {{paddingLeft: '30px' }}/>
                           
                        </div>
                        <div style={{ width: '100%', position :'relative' }}>
                        <span style = {{position : 'absolute',left:'10px',top:'50%',transform :'translateY(-50%)'}}>
                           </span>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="UsenameField"
                                placeholder="🔒 Password"
                                onKeyDown={(e)  => {if(e.key == 'Enter') handleLogin()}}
                                style= {{paddingLeft: '30px' }}
                            />
                            <button
                                type="button"
                                onClick={toggleShowPassword}
                                className="password-toggle-btn"
                            >
                                {showPassword ? <FaEyeSlash/> : <FaEye/>}
                            </button>
                        </div>
                        {
                            message ? 
                            <div>
                                <span className='message'>{message}</span>
                            </div>
                            : ''
                        }
                        {
                            error ? 
                            <div>
                                <span className='error'>{error}</span>
                            </div>
                            : ''
                        }
                        {!isIssue && (
                            <div className='flexRow alignCenter' style={{width:'100%'}}>
                                {/* {
                                    loading ? 
                                    <div className='LoginButton flexRow alignCenter'>
                                        <ButtonLoader />
                                    </div>
                                    :
                                    <button onClick={handleLogin} className='LoginButton'>Login</button>
                                } */}
                                <Button 
                                size='large'
                                variant="contained"
                                style={buttonStyle}
                                onClick={handleLogin}
                                fullWidth 
                                loading={loading} 
                                disableElevation>
                                    Login
                                </Button>
                                
                            </div>
                        )}
                        <div>
                            <span className='loginNote'>
                                <button 
                                    onClick={() => setResetPassword(!resetPassword)}
                                    style={{color:'#7636CE', background: 'none', border: 'none', cursor: 'pointer', textDecoration:'underline'}}
                                >
                                    {!resetPassword ? 'Forgot password ? Click here' : 'Click here to Login'}
                                </button>
                            </span>
                        </div>
                        { isIssue && 
                        <div style={{width:'100%'}}>
                            <input type="tel" 
                                value={phoneNumber} 
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="UsenameField" placeholder="Phone Number" />
                        </div>
                        }
                        { isIssue && 
                        <div style={{width:'100%'}}>
                            <textarea 
                                type="text" 
                                value={description } 
                                onChange={(e) => setDescription(e.target.value)}
                                className="TextArea" placeholder="Raise an issue here"/>
                        </div>
                        }
                        { isIssue && 
                        <div className='flexRow alignCenter' style={{width:'100%'}}>
                            {
                                    loading ? 
                                    <div className='LoginButton flexRow alignCenter'>
                                        <ButtonLoader />
                                    </div>
                                    :
                                    <button className='LoginButton'>Submit</button>
                            }
                        </div>
                        }
                        <div>
                            <span className='loginNote'>
                                <button 
                                    onClick={() => setIsIssue(!isIssue)}
                                    style={{color:'#7636CE', background: 'none', border: 'none', cursor: 'pointer', textDecoration:'underline'}}
                                >
                                    {!isIssue ? 'Facing trouble while logging in? Click here' : 'Click here to Login'}
                                </button>
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Login;
