import React, { useState } from 'react';

const TimePeriodSelector = () => {
  const [selected, setSelected] = useState('1D');
  
  const periods = ['1D', '7D', '1M', '3M', '1Y', 'LOG'];

  return (
    <div className="bg-[#2B3D5F] p-1 rounded-lg inline-flex gap-1 w-fit">
      {periods.map((period) => (
        <button
          key={period}
          onClick={() => setSelected(period)}
          className={`
            px-2 py-1.5 
            text-sm 
            rounded-md 
            transition-colors 
            duration-200
            ${selected === period 
              ? 'bg-[#1E2C49] text-white rounded-full' 
              : 'text-[#FFFFFF] hover:text-gray-300'
            }
          `}
        >
          {period}
        </button>
      ))}
    </div>
  );
};

export default TimePeriodSelector;