import { Close } from '@mui/icons-material';
import React, { useState } from 'react';

const ResetPasswordPopup = ({ onClose }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!newPassword) {
            errors.newPassword = 'New password is required.';
        } else if (newPassword.length < 8) {
            errors.newPassword = 'Password must be at least 8 characters long.';
        }

        if (!confirmPassword) {
            errors.confirmPassword = 'Please confirm your password.';
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match.';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleUpdatePassword = () => {
        if (!validateForm()) return;

        setLoading(true);

        // Simulate an API call
        setTimeout(() => {
            setLoading(false);
            onClose(); // Close the modal after submission
        }, 2000);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="w-[90%] md:w-[600px] bg-[#1E2944] rounded-t-3xl md:rounded-2xl shadow-lg h-[70vh] md:h-auto">
                <h2 className="relative border-b-2 border-b-[#152445] p-8 text-white text-2xl font-semibold mb-8">
                    Create Password
                    <button onClick={onClose} className="absolute right-5"><Close /></button>
                </h2>
                <form onSubmit={(e) => { e.preventDefault(); handleUpdatePassword(); }} className="px-8 pb-8 space-y-6">
                    <div>
                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
                            placeholder="Enter New Password"
                        />
                        {errors.newPassword && (
                            <p className="m-2 text-red-500 text-sm mt-1">{errors.newPassword}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Confirm Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
                            placeholder="Confirm Password"
                        />
                         {errors.confirmPassword && (
                            <p className="m-2 text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
                        )}
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-auto flex gap-2 items-center justify-center md:w-fit py-3 px-10 rounded-full text-white font-semibold transition-transform transform hover:scale-105 focus:outline-none"
                            style={{
                                background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
                            }}
                        >
                            {loading ? (
                                <>
                                    <span className="loader" /> {/* You can replace this with a loader component */}
                                    <span className="ml-2">Updating...</span>
                                </>
                            ) : (
                                'Update Password'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordPopup