import React from 'react';
import Chart from 'react-apexcharts';

const BalanceChartPanel = ({ data }) => {
    const chartData = {
        series: [
            {
                name: "Balance",
                data: data.map((item) => item.balance),
            },
        ],
        options: {
            chart: {
                type: 'area',
                height: 400,
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                },
            },
            colors: ['#5de6d1'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.6,
                    opacityTo: 0.05,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: data.map((item) => item.time),
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { style: { colors: '#A0A0A0' } },
            },
            yaxis: {
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            grid: {
                show: false,
            },
            tooltip: {
                theme: 'dark',
                style: {
                    fontSize: '12px',
                    backgroundColor: '#14213D',
                    color: 'white',
                    borderRadius: '8px',
                },
                marker: { show: true },
                x: { show: true },
            },
        },
    };

    return (
        <div>
            <Chart options={chartData.options} series={chartData.series} type="area" height={400} />
        </div>
    );
};

export default BalanceChartPanel;
