import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
// import '../Styles/TopBar.css';
import TokenParser from '../Helpers/tokenParser';
import logo from '../assets/images/logo.svg';
import { Bell, HelpCircle, Mail } from 'lucide-react';
import '../assets/css/common.css'
import TopInfoSection from './Profile/TopInfoSection';
import SidebarMobile from './SidebarMobile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Topbar = ({ setTabSelected }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [firstName, setFirstName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('')

    let token = Cookies.get('token');

    useEffect(() => {
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        let email = details.payload?.email;
        if (firstLetter) {
            setFirstName(firstLetter.substring(0, 1))
            setName(firstLetter)
            setRole(details.payload.role)
        }
        if (email) {
            setEmail(email)
        }
    }, [])

    useEffect(() => {
        if (!token) {
            Logout()
        }
    }, [])

    const openProfile = () => {
        setTabSelected('Profile');
        navigate('/profile')
    }

    const Logout = () => {
        Cookies.remove('token');
        navigate('/login')
    }

    return (
        <div className="bg-[#07142F]" style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100vh'
        }}>
            {/* Topbar Layout */}
            <nav className="bg-[#07142F] w-full px-4 md:px-8 flex justify-between items-center py-5">
                {/* Logo */}
                <Link className="flex items-center" to={role === 'user' ? '/user/home' : "/admin/home"}>
                    <img src={logo} alt="Havok Labs" className="h-10" />
                </Link>

                <SidebarMobile name={name} email={email}  role={role} />


                {/* Navigation Links (Hidden on mobile) */}
                <div className="hidden md:flex md:w-5xl space-x-6 md:justify-between text-[#9CA1AC] font-semibold">
                    <Link
                        to={role === 'user' ? '/user/home' : "/admin/home"}
                        className={location.pathname.endsWith('home') ? 'text-green-400' : ''}
                    >
                        Dashboard
                    </Link>
                    <Link
                        to="/profile"
                        className={location.pathname === '/profile' ? 'text-green-400' : ''}
                    >
                        Profile
                    </Link>
                    {/* <Link
                        to="/trade"
                        className={location.pathname === '/trade' ? 'text-green-400' : ''}
                    >
                        Trade
                    </Link> */}
                    <Link
                        to="/news"
                        className={location.pathname === '/news' ? 'text-green-400' : ''}
                    >
                        News
                    </Link>
                    <Link
                        to="/market"
                        className={location.pathname === '/market' || location.pathname.startsWith('/admin/market')  ? 'text-green-400' : ''}
                    >
                        Market
                    </Link>
                </div>

                {/* Profile Section */}
                <TopInfoSection
                    name={name}
                    email={email}
                    firstName={firstName}
                    onLogout={Logout}
                />
            </nav>

            {/* Mobile Navigation */}
            {/* <div className="md:hidden bg-[#07142F] flex justify-around py-2 text-gray-300">
                <Link
                    to="/admin/home"
                    className={location.pathname === '/admin/home' ? 'text-green-400' : ''}
                >
                    Dashboard
                </Link>
                <Link
                    to="/profile"
                    className={location.pathname === '/profile' ? 'text-green-400' : ''}
                >
                    Profile
                </Link>
                <Link
                    to="/trade"
                    className={location.pathname === '/trade' ? 'text-green-400' : ''}
                >
                    Trade
                </Link>
                <Link
                    to="/news"
                    className={location.pathname === '/news' ? 'text-green-400' : ''}
                >
                    News
                </Link>
                <Link
                    to="/market"
                    className={location.pathname === '/market' ? 'text-green-400' : ''}
                >
                    Market
                </Link>
            </div> */}

            <div className='mt-5 overflow-auto scrollbar-hidden' style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                // overflow: 'hidden',
                height: '100%'
            }}>
                <div className="bg-[#152445] rounded-tl-[30px] rounded-tr-[30px] p-5 md:px-10 md:py-12 scrollbar-hidden min-h-screen max-h-auto overflow-y-auto">
                    <div className='md:max-w-[1700px] md:mx-auto overflow-y-scroll scrollbar-hidden'
                    style={{
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                      }}>
                        {/* This renders the child routes */}
                        <Outlet />
                        <ToastContainer stacked />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Topbar;
