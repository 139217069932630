import sampleProfile from '../assets/images/sampleProfile.svg';
import sampleImage from '../assets/images/sampleNewsDetail.svg';
import { getFirstName } from '../services/common.utils';

const NewsDetails = () => {

    const cards = [{
        id: 1,
        image: sampleImage,
        postedBy: 'John Doe',
        postedOn: 'Aug 23, 2024',
        title: 'Bitcoin Maintains Stability While Altcoins Rally ',
        content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.'
    },
    {
        id: 2,
        image: sampleImage,
        postedBy: 'John Doe',
        postedOn: 'Aug 23, 2024',
        title: 'Bitcoin Maintains Stability While Altcoins Rally ',
        content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.'
    },
    {
        id: 3,
        image: sampleImage,
        postedBy: 'John Doe',
        postedOn: 'Aug 23, 2024',
        title: 'Bitcoin Maintains Stability While Altcoins Rally ',
        content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.'
    },
    {
        id: 4,
        image: sampleImage,
        postedBy: 'John Doe',
        postedOn: 'Aug 23, 2024',
        title: 'Bitcoin Maintains Stability While Altcoins Rally ',
        content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.'
    }]

    const SuggestedCard = (card) => {
        return (
            <div className='bg-[#37445E] p-4 gap-4 mt-2 rounded-2xl md:rounded'>
                <div className='aspect-video'>
                    <img src={card.card.image} alt='cardImg' className='aspect-video' />
                </div>
                <div className='flex flex-col gap-4'>
                    <span className='mt-4 text-[#FFF]'>
                        By Hohn Doe | {card.card.postedOn}
                    </span>
                    <span className='mt-4 text-[#FFF] text-md font-extrabold'>
                        Bitcoin Maintains Stability While Altcoins Rally
                    </span>
                    <span className='mt-4 text-[#FFF] text-sm'>
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
                    </span>
                </div>
            </div>
        )
    }
    return (
        <div className='flex flex-col items-center gap-5'>
            <p className='md:hidden font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>

            <div className="flex flex-col items-center gap-5">
                <div className='md:max-w-[70%] flex flex-col gap-5 w-full'>
                    <div className="flex flex-row justify-start gap-5">
                        <div>
                            <img src={sampleProfile} alt='' />
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-[#20D9B7] text-lg font-bold'>BH News</span>
                            <span className='text-[#D9D9D9] text-sm'>Posted on 27th January 2024</span>
                        </div>
                    </div>
                    <div>
                        <span className='text-[#EFEFF4] font-extrabold text-2xl'>Bitcoin Maintains Stability While Altcoins Rally</span>
                    </div>
                </div>
                <div className='my-3'>
                    <img src={sampleImage} alt='' />
                </div>
                <div className='md:max-w-[70%] flex flex-col gap-3'>
                    <div>
                        <div className='text-[#D5D8DD] font-bold text-xl'>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</span>
                        </div>
                        <div className='text-[#7A8294] text-sm'>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                        </div>
                    </div>
                    <div>
                        <div className='text-[#D5D8DD] font-bold text-xl'>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</span>
                        </div>
                        <div className='text-[#7A8294] text-sm'>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                        </div>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                    <div className='text-[#7A8294] text-sm'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</span>
                    </div>
                </div>
            </div>
            <div className='md:max-w-[90%]'>
                <div className='mt-5'>
                    <span className='text-[#37B3B7] font-bold text-2xl'>
                        What to read next
                    </span>
                </div>
                <div className='flex flex-col md:flex-row gap-4'>
                    {
                        cards.slice(0,3).map((card) => (
                            <SuggestedCard card={card} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default NewsDetails;