import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import App from "./App";
import Login from '../src/Screens/Login'
import ResetPassword from '../src/Screens/ResetPassword'
import Dashboard from "./Screens/DashBoard";
import Profile from "./Screens/Profile";
import Trade from "./Screens/Trade";
import AddUser from "./Screens/Users/AddUser";
import UserDashBoardAdminView from "./Screens/UserDashBoardAdminView";
// import Topbar from "./Components/TopBar";
import DashboardHome from "./Screens/DashboardHome";
import MarketDetails from "./Screens/Market/MarketDetails";
import LoginPage from "./Components/Auth/Login";
import AdminDashboard from "./Screens/AdminDashboard";
import UserDashboard from "./Screens/Dashboard/UserDashboard";
import Information from "./Components/Profile/Information";
import News from "./Screens/News";
import NewsDetails from "./Screens/NewsDetails";
import ResetPasswordUser from "./Screens/Users/ResetPassword";


const router = createBrowserRouter([
  {
    path: "*",
    element: <LoginPage />
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/login-old",
    element: <Login />
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: '/',
        element: <AdminDashboard />
      },
      {
        path: '/admin/home',
        element: <DashboardHome />
      },
      {
        path: '/admin/home-old',
        element: <AdminDashboard />
      },
      {
        path: '/admin/users/:userId',
        element: <UserDashBoardAdminView />
      },
      {
        path: '/profile',
        element: <Information />
      },
      {
        path: '/market',
        element: <Trade />
      },
      {
        path: '/news',
        element: <News />
      },
      {
        path: '/news/:newsid',
        element: <NewsDetails />
      },
      {
        path: '/adduser',
        element: <AddUser />
      },
      {
        path: '/admin/market/:id',  
        element: <MarketDetails />
      },
      // user routes
      {
        path: '/user/home',
        element: <UserDashboard />
      },
      {
        path: '/user/reset-password',
        element: <ResetPasswordUser />
      },
    ]
  },
  

]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);