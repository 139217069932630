import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import { Loader } from 'lucide-react';
import bg from '../assets/images/loginBg.svg';
import logo from '../assets/images/logo.svg';

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    // Extract the token from the query string
    const queryParams = new URLSearchParams(location.search);
    const resetPassToken = queryParams.get('token');

    useEffect(() => {
        // Optional: Add redirect logic if a token already exists
    }, []);

    const handleUpdatePassword = () => {
        setLoading(true);
        let headers = {}; // Use headers if necessary
        let apiRequest = {
            "newPassword": confirmPassword
        };
        axios.post(`https://backend.luxara.ai/api/v1/resetPassword?token=${resetPassToken}`, apiRequest, { headers })
            .then(() => {
                setLoading(false);
                setMessage("Password reset successfully. Redirecting to login...");
                setTimeout(() => navigate('/login'), 2000);
            })
            .catch((apiError) => {
                setError(apiError.response?.data?.message || "Error resetting password");
                setLoading(false);
                if (apiError.response?.status === 401) {
                    navigate('/login');
                }
            });
    };

    return (
        // <div className="flex items-center justify-center w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${bg})` }}>
        //     <div className="flex flex-col w-full items-center md:flex-row bg-opacity-90 p-4 md:p-10 rounded-lg shadow-lg bg-darkBlue">
        //         <div className="flex-1 md:w-2/4 flex justify-center mb-4 md:mx-auto">
        //             <img src={logo} alt="Havok Labs" className="w-60 md:w-2/3 h-auto" />
        //         </div>
        //         <div className="md:flex-0 lg:w-2/4 w-full">
        //             <div className='max-w-xl mx-auto bg-[#1E2944] p-8 rounded-2xl shadow-lg'>
        //                 <h2 className="text-white text-2xl font-semibold mb-8">Reset Password</h2>
        //                 <form onSubmit={(e) => { e.preventDefault(); handleUpdatePassword(); }} className="space-y-6">
        //                     <div>
        //                         <label className="block text-[#D3D5DB] text-sm font-medium mb-2">New Password</label>
        //                         <input
        //                             type="password"
        //                             value={newPassword}
        //                             onChange={(e) => setNewPassword(e.target.value)}
        //                             className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
        //                             placeholder="Enter New Password"
        //                         />
        //                     </div>
        //                     <div>
        //                         <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Confirm Password</label>
        //                         <input
        //                             type="password"
        //                             value={confirmPassword}
        //                             onChange={(e) => setConfirmPassword(e.target.value)}
        //                             className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
        //                             placeholder="Confirm Password"
        //                         />
        //                     </div>
        //                     <div className="flex justify-center">
        //                         <button
        //                             type="submit"
        //                             disabled={loading}
        //                             className="w-auto flex gap-2 items-center justify-center md:w-1/3 py-3 px-10 rounded-full text-white font-semibold transition-transform transform hover:scale-105 focus:outline-none"
        //                             style={{
        //                                 background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
        //                             }}
        //                         >
        //                             {loading ? (
        //                                 <>
        //                                     <Loader size={15} />
        //                                     <span className="ml-2">Updating...</span>
        //                                 </>
        //                             ) : (
        //                                 'Update Password'
        //                             )}
        //                         </button>
        //                     </div>
        //                 </form>
        //                 {message && <p className="text-green-500 mt-4">{message}</p>}
        //                 {error && <p className="text-red-500 mt-4">{error}</p>}
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="flex md:items-center md:justify-center w-full h-screen bg-cover bg-center"
            style={{ backgroundImage: window.innerWidth > 1200 ? `url(${bg})` : 'none' }}>
            <div className="flex flex-col h-screen md:h-auto w-full items-center md:flex-row bg-opacity-90 md:p-10 rounded-lg shadow-lg bg-darkBlue">
                <div className="flex-1 md:w-2/4 md:flex justify-center mb-4 md:mx-auto hidden">
                    <img src={logo} alt="Havok Labs" className="w-90 h-auto" />
                </div>
                <div className="md:hidden h-[30vh] bg-cover bg-center flex items-center justify-center w-full"
                    style={{ backgroundImage: `url(${bg})` }}>
                    <img src={logo} alt="Havok Labs" className="w-60 h-auto" />
                </div>
                <div className="md:flex-0 lg:w-2/4 w-full">
                    <div className='max-w-xl mx-auto bg-[#1E2944] rounded-t-3xl md:rounded-2xl shadow-lg h-[70vh] md:h-auto'>
                        <h2 className="border-b-2 border-b-[#152445] p-8 text-white text-2xl font-semibold mb-8">Reset Password</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleUpdatePassword(); }} className="px-8 pb-8 space-y-6">
                            <div>
                                <label className="block text-[#D3D5DB] text-sm font-medium mb-2">New Password</label>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
                                    placeholder="Enter New Password"
                                />
                            </div>
                            <div>
                                <label className="block text-[#D3D5DB] text-sm font-medium mb-2">Confirm Password</label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="w-full p-3 rounded-full bg-[#26334E] text-white outline-none border border-transparent focus:border-blue-500"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-auto flex gap-2 items-center justify-center md:w-fit py-3 px-10 rounded-full text-white font-semibold transition-transform transform hover:scale-105 focus:outline-none"
                                    style={{
                                        background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
                                    }}
                                >
                                    {loading ? (
                                        <>
                                            <Loader size={15} />
                                            <span className="ml-2">Updating...</span>
                                        </>
                                    ) : (
                                        'Update Password'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
