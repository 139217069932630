import React, { useState } from 'react';
import { getFirstName } from '../../services/common.utils';
import { useNavigate } from 'react-router-dom';
import { Send, X } from 'lucide-react';

const InquiryResponse = ({ data }) => {
    console.log(data)
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        initialBalance: '',
        adminResponse: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        setLoading(false)

        // Validation
        if (!formData.fullName) {
            setError('Please enter your full name');
            return;
        }
        if (!formData.email || !formData.email.includes('@')) {
            setError('Please enter a valid email address');
            return;
        }
        if (!formData.initialBalance || isNaN(formData.initialBalance)) {
            setError('Please enter a valid initial balance');
            return;
        }

        // Reset error and set success message
        setError('');
        setSuccess('Your inquiry has been submitted successfully!');

        // Reset form data
        setFormData({
            fullName: '',
            email: '',
            initialBalance: '',
        });
    };

    return (
        <div className="h-full text-white md:p-6">
            {/* Header */}
            <h1 className="text-4xl font-light mb-12 text-center md:text-start">
                Hello <span className="text-[#3CC8C8] capitalize">{getFirstName()}</span>
            </h1>
            <div className="max-w-4xl mx-auto">
                <div className="text-white">
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Inquiry</h2>
                        <button className="bg-[#2B3C5E] text-opacity-50 text-white rounded-full px-8 py-1 float-right">
                            Close
                        </button>
                    </div>
                    <div className='w-full flex flex-col md:flex-row gap-5 justify-between'>
                        <div className='md:w-1/4'>
                        </div>
                        <div className='md:w-3/4'>
                            <form onSubmit={handleSubmit} className="mt-8 space-y-4">
                                <div className='bg-[#2B3D5F] md:p-7 p-5 rounded-3xl'>
                                    {data?.clientMessage}
                                </div>
                                <div>
                                    <textarea
                                        id="adminResponse"
                                        name="adminResponse"
                                        value={formData.adminResponse}
                                        onChange={handleChange}
                                        className="focus:outline-none outline-none bg-transparent border-2 border-[#3CC8C8] rounded-3xl text-white md:p-7 p-5 w-full h-32"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    style={{ background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)' }}
                                    onClick={handleSubmit}
                                    className="w-fit px-12 float-right text-white rounded-full py-4 font-medium hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
                                >
                                    {loading ? (
                                        <>
                                            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                            <span>Sending ...</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>Send</span>
                                        </>
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default InquiryResponse