import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import NewsSearchSort from './NewsSearchSort';
import { getFirstName } from '../../services/common.utils';
import NewsCard from '../News/NewsCard';

const NewsFeed = ({ title, articles }) => {
  const navigate = useNavigate();
  return (
    <div className='md:bg-[#202F4C] md:p-10 md:rounded-2xl'>
     

      <h1 className="text-2xl font-bold text-[#37B3B7] mb-6 text-center md:text-start">{title}</h1>

      <div className="space-y-4">
        {articles?.map((article) => (
          <NewsCard article={article} navigate={navigate} />
        ))}
      </div>


    </div>
  );
};

export default NewsFeed;