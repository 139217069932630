import React, { useState, useRef, useEffect } from 'react';
import { HelpCircle, Mail, Bell, LogOut, Settings, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TopInfoSection = ({ name, email, firstName, onLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate()

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="hidden md:flex items-center space-x-4">
      {/* Action Buttons */}
      <button className="text-white bg-gray-700 hover:bg-gray-600 p-2 rounded-full transition-colors">
        <HelpCircle className="w-5 h-5" />
      </button>
      <button className="text-white bg-gray-700 hover:bg-gray-600 p-2 rounded-full transition-colors">
        <Mail className="w-5 h-5" />
      </button>
      <button className="text-white bg-gray-700 hover:bg-gray-600 p-2 rounded-full relative transition-colors">
        <Bell className="w-5 h-5" />
        <span className="absolute top-0 right-0 h-2 w-2 bg-red-600 rounded-full"></span>
      </button>

      {/* User Info with Dropdown */}
      <div className="relative" ref={dropdownRef}>
        <button 
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center space-x-2 hover:bg-gray-700 rounded-lg p-2 transition-colors"
        >
          <div className="bg-gradient-to-r from-blue-500 to-[#4ECDC4] text-white rounded-full w-8 h-8 flex items-center justify-center">
            {firstName.charAt(0)}
          </div>
          <div className="ml-2 text-white flex flex-col justify-start items-start">
            <p className="text-sm font-medium">{name}</p>
            {email && <p className="text-gray-400 text-xs">{email}</p>}
          </div>
        </button>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-lg bg-[#232D45] border border-gray-700 shadow-lg py-1 z-50">
            {/* <div className="px-4 py-3 border-b border-gray-700">
              <p className="text-sm text-white font-medium truncate">{name}</p>
              <p className="text-xs text-gray-400 truncate">{email}</p>
            </div> */}
            
            <button 
              className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center space-x-2"
              onClick={() => navigate('/profile')}
            >
              <User className="w-4 h-4" />
              <span>Profile</span>
            </button>
            
            {/* <button 
              className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center space-x-2"
              onClick={() => {}}
            >
              <Settings className="w-4 h-4" />
              <span>Settings</span>
            </button>
             */}
            <div className="border-t border-gray-700">
              <button 
                className="w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-700 flex items-center space-x-2"
                onClick={onLogout}
              >
                <LogOut className="w-4 h-4" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopInfoSection