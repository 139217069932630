import { Maximize } from 'lucide-react';
import React from 'react';

const BotPerformanceCard = ({title, link, img}) => {
    const handleLink = () => {
        // open in new tab
        window.open(link, '_blank');
    }

    return (
        <div className="bg-[#2C3957] rounded-xl p-8 shadow-lg text-white">
            <div className="flex justify-between items-center mb-2">
                <div>
                    <h2 className="text-2xl font-semibold">{title}</h2>
                    <p className="text-md font-medium">Bot Performance</p>
                </div>
                <button className="text-gray-400 hover:text-white" onClick={handleLink}>
                    <Maximize size={20} />
                </button>
            </div>
            <div className="rounded-2xl overflow-hidden cursor-pointer mt-5" onClick={handleLink}>
                <img
                    src={img}
                    alt="Bot Performance Chart"
                    className="w-full h-[200px] object-cover"
                />
            </div>
        </div>
    );
};

export default BotPerformanceCard;
