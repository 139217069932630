import logo from './logo.svg';
import './App.css';
import Home from './Screens/Home';
import Login from './Screens/Login';
import Topbar from './Components/TopBar';

function App() {
  return (
    // <div className="App">
    //     <Home />
    // </div>
    <Topbar />
  );
}

export default App;
