import { ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';

const WalletActivityHeader = () => {
    const [selectedOption, setSelectedOption] = useState("Default");
    const [isOpen, setIsOpen] = useState(false);

    const options = ["Default", "Option 1", "Option 2"]; // Add more options as needed

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <div className="flex justify-between items-center bg-[#202F4C] py-2 rounded-lg w-full">
            {/* Title */}
            <h2 className="text-white text-lg md:text-xl font-semibold">Wallet Activity</h2>
            
            {/* Dropdown */}
            <div className="relative md:hidden bg-[#212C45] rounded-full p-2">
                <button 
                    onClick={toggleDropdown} 
                    className="flex items-center bg-[#202F4C] text-white py-1 px-3 rounded-lg"
                >
                    {selectedOption}
                    <ChevronDownIcon className="w-5 h-5 ml-1 text-white" />
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="absolute right-0 mt-2 w-32 bg-[#202F4C] rounded-lg">
                        {options.map((option) => (
                            <button
                                key={option}
                                onClick={() => handleOptionClick(option)}
                                className="block w-full text-left px-4 py-2 text-white hover:bg-[#1B2E4E] rounded-lg"
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WalletActivityHeader;
