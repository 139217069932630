import { Settings } from 'lucide-react';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import upIcon from '../../assets/images/user/up.svg'
import BalanceChartPanel from '../../Components/Analytics/BalanceChart';

const data = [
    { time: '12:00', balance: 100000 },
    { time: '13:00', balance: 231234.41 },
    { time: '14:00', balance: 220000 },
    { time: '15:00', balance: 235000 },
    { time: '16:00', balance: 210000 },
    { time: '17:00', balance: 223112 },
    { time: '18:00', balance: 215000 },
];


const BalanceChart = () => {
    const [isOpen, setIsOpen] = useState(false);
    const years = ['2024', '2023', '2022', '2021', '2020'];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const YearDropdown = () => (
        <div className="relative">
            <button
                className="flex items-center text-[#3CC8C8] focus:outli[ne-none border-[1px] border-[#3CC8C8] rounded-full p-2 px-4"
                onClick={toggleDropdown}
            >
                <span>2024</span>
                <FaChevronDown className="ml-2" />
            </button>
            {isOpen && (
                <ul className="absolute top-12 right-0 bg-gray-900 rounded-lg shadow-lg z-10">
                    {years.map((year) => (
                        <li
                            key={year}
                            className="px-4 py-2 hover:bg-gray-800 cursor-pointer"
                            onClick={() => setIsOpen(false)}
                        >
                            {year}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    const SettingsButton = () => {
        return (
            <button className="text-[#3CC8C8] focus:outline-none border-[1px] border-[#3CC8C8] rounded-full p-2">
                <Settings size={18} />
            </button>
        );
    };

    const BalanceHeader = () => {
        return (
            <div className='flex flex-col gap-2'>
                <div className="flex items-center justify-end md:justify-between mb-4 text-[#FFFFFF]">
                    <div className='md:flex flex-col space-y-2 flex-1 hidden'>
                        <p className="text-2xl font-bold">Balance</p>
                        <div className="flex items-center">
                            <p className="text-2xl font-bold">$223,112</p>
                            <p className="text-sm text-[#33CCCC] mx-2 flex">
                                <img src={upIcon} alt='upIcon' className='me-1' /> 21%
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <YearDropdown />
                        <SettingsButton />
                    </div>
                </div>
                <div className='flex p-5 flex-col space-y-2 flex-1 md:hidden bg-[#37435E] rounded-xl'>
                    <p className="text-2xl font-bold">Balance</p>
                    <div className="flex items-center">
                        <p className="text-2xl font-bold">$223,112</p>
                        <p className="text-sm text-[#33CCCC] mx-2 flex">
                            <img src={upIcon} alt='upIcon' className='me-1' /> 21%
                        </p>
                    </div>
                </div>
            </div>

        );
    };
    return (
        <div className="bg-[#2C3957] text-white rounded-2xl overflow-hidden shadow-lg">
            <div className="p-8">
                <BalanceHeader />
            </div>

            {/* Chart */}
            <BalanceChartPanel data={data} />
        </div>
    );
};

export default BalanceChart;