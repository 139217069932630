import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Defs,
    LinearGradient,
    Stop,
    Line,
    ResponsiveContainer,
    BarChart,
} from "recharts";

import { useEffect, useState } from "react";

const HangingHistogram = ({ height = 450, radius = '30px', bg = '#37435e', width = null, setSelectedChartDropdown }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [data, setData] = useState([
        { name: '10', loss: 40, profit: 50 },
        { name: '11', loss: 120, profit: 100 },
        { name: '12', loss: 120, profit: 60 },
        { name: '13', loss: 90, profit: 60 },
        { name: '14', loss: 110, profit: 90 },
        { name: '15', loss: 150, profit: 70 },
        { name: '16', loss: 80, profit: 40 },
        { name: '17', loss: 200, profit: 40 },
        { name: '18', loss: 90, profit: 30 },
        { name: '19', loss: 100, profit: 80 }
    ])

    const [selectedOption, setSelectedOption] = useState('Monthly'); // Default to Monthly

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        let value = event.target.value;
        setSelectedChartDropdown(value)
        // Additional logic here (e.g., fetching data for the selected timeframe)
        if (value === 'Daily') {
            setData([
                { bin: "1", Loss: 0, Profit: 16 },
                { bin: "2", Loss: 0, Profit: 15 },
                { bin: "3", Loss: 15, Profit: 0 },
                { bin: "4", Loss: 12, Profit: 0 },
                { bin: "5", Loss: 0, Profit: 12 },
                { bin: "6", Loss: 22, Profit: 0 },
                { bin: "7", Loss: 0, Profit: 8 },
                { bin: "8", Loss: 0, Profit: 28 },
                { bin: "9", Loss: 10, Profit: 0 },
                { bin: "10", Loss: 0, Profit: 14 },
                { bin: "11", Loss: 5, Profit: 0 },
                { bin: "12", Loss: 0, Profit: 18 },
                { bin: "13", Loss: 0, Profit: 22 },
                { bin: "14", Loss: 9, Profit: 0 },
                { bin: "15", Loss: 0, Profit: 10 },
                { bin: "16", Loss: 13, Profit: 0 },
                { bin: "17", Loss: 0, Profit: 24 },
                { bin: "18", Loss: 7, Profit: 0 },
                { bin: "19", Loss: 0, Profit: 20 },
                { bin: "20", Loss: 0, Profit: 26 },
                { bin: "21", Loss: 17, Profit: 0 },
                { bin: "22", Loss: 0, Profit: 11 },
                { bin: "23", Loss: 6, Profit: 0 },
                { bin: "24", Loss: 0, Profit: 13 },
                { bin: "25", Loss: 14, Profit: 0 },
                { bin: "26", Loss: 0, Profit: 19 },
                { bin: "27", Loss: 8, Profit: 0 },
                { bin: "28", Loss: 0, Profit: 9 },
                { bin: "29", Loss: 18, Profit: 0 },
                { bin: "30", Loss: 0, Profit: 21 },
            ]);
        } else if (value === 'Monthly') {
            setData([
                { bin: "Jan", Loss: 50, Profit: 0 },
                { bin: "Feb", Loss: 0, Profit: 90 },
                { bin: "Mar", Loss: 45, Profit: 0 },
                { bin: "Apr", Loss: 0, Profit: 70 },
                { bin: "May", Loss: 55, Profit: 0 },
                { bin: "Jun", Loss: 0, Profit: 95 },
                { bin: "Jul", Loss: 50, Profit: 0 },
                { bin: "Aug", Loss: 0, Profit: 120 },
                { bin: "Sep", Loss: 45, Profit: 0 },
                { bin: "Oct", Loss: 0, Profit: 90 },
                { bin: "Nov", Loss: 50, Profit: 0 },
                { bin: "Dec", Loss: 0, Profit: 105 },
            ]);
        } else if (value === 'Yearly') {
            setData([
                { bin: "2020", Loss: 500, Profit: 0 },
                { bin: "2021", Loss: 0, Profit: 1800 },
                { bin: "2022", Loss: 600, Profit: 0 },
                { bin: "2023", Loss: 0, Profit: 1900 },
            ]);
        }
    };


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="rounded-xl p-4 shadow-lg" style={{
                    background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)'
                }}>
                    <p className="text-sm font-medium">{`${label}`}</p>
                    {payload.map((entry, index) => (
                        <p
                            key={index}
                            style={{ color: entry.color }}
                            className="text-sm"
                        >
                            {
                                entry.value !== 0
                                    ?
                                    `${entry.name}: ${entry.value}`
                                    : ''
                            }
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: bg,
            padding: '16px',
            borderRadius: radius,
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: 20
                }}
            >
                <div className="label" style={{
                    fontWeight: 600
                }}>Daily Profit / Loss</div>
                <div className="text-end">
                    <select
                        className="bg-[#212C45] text-white p-2 px-4 rounded-xl border-px border-[#212C45]"
                        value={selectedOption}
                        onChange={handleChange}
                    >
                        <option value="Daily">Daily</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                    </select>
                </div>
            </div>
       
            <BarChart
                width={width ? width : (selectedOption === 'Daily') ? 1000 :600}
                height={height}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: isMobile ? 0 : 20,
                    bottom: 5,
                }}
            >
                <defs>
                    <linearGradient
                        id="colorProfit"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                    >
                        <stop offset="20%" stopColor="rgb(119, 112, 255)" />
                        <stop offset="100%" stopColor="rgb(14, 238, 168)" />
                    </linearGradient>
                    <linearGradient
                        id="colorLoss"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                    >
                        <stop offset="0%" stopColor="#D724FC" />
                        <stop offset="100%" stopColor="#7770FF" />
                    </linearGradient>
                </defs>
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#333"
                />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: 'rgba(255, 255, 255, 0.5)', fontSize: 12 }}

                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: 'rgba(255, 255, 255, 0.5)', fontSize: 12 }}
                    ticks={[0, 50, 100, 150, 200, 250, 300]}
                    tickFormatter={(value) => `$${value}`}
                />
                <Tooltip content={<CustomTooltip />} cursor={false}/>

                <Bar
                    dataKey="loss"
                    stackId="a"
                    fill="url(#colorLoss)"
                    x1="0" y1="1" x2="0" y2="0"
                    radius={[0, 0, 15, 15]}
                    barSize={isMobile ? 10 : 30}
                />
                <Bar
                    dataKey="profit"
                    stackId="a"
                    fill="url(#colorProfit)"
                    x1="1" y1="1" x2="1" y2="1"
                    radius={[15, 15, 0, 0]}
                    barSize={isMobile ? 10 : 30}
                />
            </BarChart>
        </div>
    )
}

export default HangingHistogram;