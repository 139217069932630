/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import BarPlot from '../Components/BarPlot'
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TokenParser from '../Helpers/tokenParser';
import Cookies from 'js-cookie';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import ButtonLoader from '../Helpers/buttonLoader';
import { Search, EllipsisVertical } from 'lucide-react';
import Dropdown from "../Components/DropDownCheckBoxes";
import filterIcon from '../assets/images/filter.svg'

import Plus from '../assets/plus.svg'
import '../Styles/UserDashboardAdminView.css'
import { getFirstName, numericValue } from "../services/common.utils";
import { FaFilter, FaSearch } from "react-icons/fa";
import sampleData from '../Helpers/sampleWalletData'
import graph from '../assets/images/market/graph.svg'
import graphRed from '../assets/images/graphRed.svg'
import WalletActivityHeader from "./Users/WalletActivityHeader";
import { Close } from "@mui/icons-material";
import AddFunds from "../Components/Modal/AddFunds";
import ResetPasswordPopup from "./Users/ResetPasswordPopup";
import InquiryResponse from "./Users/InquiryResponse";

const UserDashBoardAdminView = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isResetVisible, setIsResetVisible] = useState(false);

    const openResetPopup = () => {
        setIsResetVisible(true);
    };

    const closeResetPopup = () => {
        setIsResetVisible(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const userId = pathSegments[pathSegments.length - 1];

    const navigate = useNavigate();
    const [adminName, setAdminName] = useState('');
    const [userName, setUserName] = useState('');
    const [PNLType, setPNLType] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [updatedAt, setUpdatedAt] = useState("");
    const [previousBalance, setPreviousBalance] = useState("");
    const [openTrades, setOpenTrades] = useState(0);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState(0);
    const [openTrade, setOpenTrade] = useState(false);
    const [loading, setLoading] = useState(false);
    const [walletData, setWalletData] = useState([]);
    const [PNL, setPNL] = useState(0);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageUser, setCurrentPageUser] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [isActionOpen, setActionOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [addFunds, setAddFunds] = useState('');
    const [fundLoader, setFundLoader] = useState(false)
    const [selectedChartDropdowndOption, setSelectedChartDropdown] = useState('Monthly'); // Default to Monthly
    const [isOpenResponse, setIsOpenResponse] = useState(false)
    const [selectedInquiryData, setSelectedInquiryData] = useState({})
    const recordsPerPage = 5;
    const recordsPerPageUser = 10;

    const [inquirySearch, setInquirySearch] = useState('');
    const [isEdit, setEdit] = useState(false);
    const userDetails = require('../assets/json/UserDetails.json')

    const [selectedValue, setSelectedValue] = useState("");
    // const [chartData, setChartData] = useState([])

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (selectedValue) {
            fetchDetails(selectedValue)
        }
    }, [selectedValue])

    const fetchDetails = async (timeframeValue) => {
        setLoading(true);
        let token = Cookies.get("token");
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKEND}getUserProfile?timeframe=${timeframeValue}&id=${userId}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                console.log("apiResponse", apiResponse.data);
                setUserName(apiResponse.data?.username);
                setTotalBalance(apiResponse.data?.balance);
                setPreviousBalance(apiResponse.data?.previousBalance);
                setUpdatedAt(apiResponse.data?.lastUpdatedOn);
                setPNLType(apiResponse.data?.profitLossType);
                setSubscriptionPeriod(apiResponse.data?.subscriptionPeriod);
                setOpenTrade(apiResponse.data?.enableTrade);
                setOpenTrades(apiResponse.data?.openTrades);
                setPNL(
                    apiResponse.data?.profitLossAmount
                );
                setEmail(apiResponse?.data?.email);
                setPhoneNumber(apiResponse.data?.phoneNumber);
                setName(apiResponse.data?.firstName + ' ' + apiResponse.data?.lastName);
            })
            .catch((apiError) => {
                console.log("apiError", apiError);
                console.log("apiError", apiError.messsage);
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });

        headers["userId"] = userId;

        await axios.get(`${process.env.REACT_APP_BACKEND}history`, { headers: headers, })
            .then((apiResponse) => {
                console.log("wallet activity", apiResponse.data);
                setWalletData(apiResponse.data?.stats);
                setRecordsFiltered(apiResponse.data?.recordsFiltered)
                setLoading(false);
            })
            .catch((apiError) => {
                console.log("apiError", apiError);
                console.log("apiError", apiError.messsage);
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });
    };

    const fetchChartDetails = async (timeframeValue) => {
        setLoading(true);
        let token = Cookies.get("token");
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKEND}stats?timeframe=${timeframeValue}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                console.log("apiResponse", apiResponse.data);
            })
            .catch((apiError) => {
                console.log("apiError", apiError.messsage);
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });

        headers["userId"] = userId;

        await axios.get(`${process.env.REACT_APP_BACKEND}history`, { headers: headers, })
            .then((apiResponse) => {
                console.log("wallet activity", apiResponse.data);
                setWalletData(apiResponse.data?.stats);
                setRecordsFiltered(apiResponse.data?.recordsFiltered)
                setLoading(false);
            })
            .catch((apiError) => {
                console.log("apiError", apiError);
                console.log("apiError", apiError.messsage);
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });
    };

    useEffect(() => {
        let token = Cookies.get("token");
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setAdminName(firstLetter);
        }
        fetchDetails("");
    }, []);

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log(event.key);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        renderWalletTable();
    }, [walletData])

    const handleSave = () => {

    }

    const ActionButtons = ({
        item,
        index
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef(null);

        // Close dropdown when clicking outside
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        return (
            <div className="relative flex px-2 gap-3 items-center justify-around md:justify-center" ref={dropdownRef}>
                {/* Dropdown Trigger Button */}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center gap-2 py-2 rounded-full text-white"
                >
                    <EllipsisVertical></EllipsisVertical>
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="absolute top-7 mt-2 w-48 bg-[#2A3B59] rounded-lg shadow-lg py-1 z-10 text-white">
                        <div className="flex justify-between items-center px-4 py-2 border-b border-[#28274D]">
                            <span className="text-sm">Action</span>
                        </div>
                        <div className='w-[100%] flex flex-col items-center justify-center'>
                            {/* Update Balance Option */}
                            <button
                                onClick={() => {
                                    setIsOpenResponse(true);
                                    setSelectedInquiryData(item);
                                }}
                                className="w-[80%] text-white mt-2 px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"
                            >
                                Reply
                            </button>

                            {/* Add Funds Option */}
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                View more
                            </button>

                            {/* View Option */}
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="w-[80%] text-white px-4 py-2 text-left hover:border hover:border-[#3CC8C8] hover:rounded-full flex items-center gap-2"

                            >
                                Resolve Enquiry
                            </button>
                        </div>
                    </div>
                )}


            </div>
        );
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const handlePageChangeUser = (page) => {
        setCurrentPageUser(page);
    }
    const ClientMessage = ({ content }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <p className={`truncate-text ${isExpanded ? 'expanded' : ''}`}>
                    {content}
                </p>
                {content.split(' ').length > 20 && ( // Only show "Read More" if content is long
                    <span
                        className="read-more-toggle text-[#33CCCC] cursor-pointer"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? 'Read less' : '... Read more'}
                    </span>
                )}
            </div>
        );
    };

    const onAddFunds = (item, index) => {
        setOpenAdd(true);
    }

    const onCloseAdd = () => {
        setOpenAdd(false);
    }

    const addFundAction = () => {
        setFundLoader(true)
        let token = Cookies.get('token');
        let headers = {
            Authorization: `Bearer ${token}`
        }
        let apiRequest = {
            "userId": userId,
            "subscriptionPeriod": subscriptionPeriod,
            "openTrades": openTrades
        }
        // if (balanceUpdate) {
        apiRequest.type = 'Profit';
        apiRequest.amount = Number(addFunds) + (PNL ? numericValue(PNL) : 0);
        // }
        // console.log('apiRequest', apiRequest)
        axios.post(`${process.env.REACT_APP_BACKEND}updateBalance`, apiRequest, { headers: headers })
            .then((apiResponse) => {
                // console.log('apiResponse', apiResponse.data);
                // let temp = [...data];
                // currentUser.profitLossType = selectType;
                // if (numericValue(totalBalance) > 0) {
                // 	currentUser.balance = Number(currentUser.balance) + Number(currentUser.profitLossAmount);
                // } else if (selectType === 'Loss') {
                // 	currentUser.balance = Number(currentUser.balance) - Number(currentUser.profitLossAmount);
                // }
                // temp[currentIndex] = currentUser;
                // console.log('currentUser@@@@@@@@', currentUser)
                // setData(temp);
                setAddFunds(0)
                fetchChartDetails(selectedChartDropdowndOption)
                setFundLoader(false);
                setOpenAdd(false);
            })
            .catch((apiError) => {
                setOpenAdd(false);
                setAddFunds(0)
                // console.log('apiError', apiError.messsage);
                setFundLoader(false);
                if (apiError.response.status === 401) {
                    navigate('/login')
                }
            })
    }

    const renderWalletTable = () => {
        return (
            <div className='mobileTable userDataTable'>
                {isMobile ?
                    <div className="w-full max-w-md mx-auto">
                        {currentRecords.map((item, index) => (
                            <div key={index} className="flex space-x-4 items-center justify-between bg-[#202F4C] py-3">
                                {/* Icon */}
                                <div className="w-10 h-10 flex items-center justify-center mr-4">
                                    <span
                                        className={`w-full h-full rounded-full flex items-center justify-center`}
                                    >
                                        <img
                                            src={index % 2 === 0 ? graph : graphRed}
                                            alt="Transaction Icon"
                                            className="w-8 h-8"
                                        />
                                    </span>
                                </div>

                                {/* Date & Time */}
                                <div className="flex-1 gap-1">
                                    <div className="text-gray-400 text-xs">Date & Time</div>
                                    <div className="text-white text-sm font-semibold">{item.updatedOn.slice(11)}</div>
                                    <div className="text-gray-400 text-xs">{item.updatedOn.slice(0, 10)}</div>
                                </div>

                                {/* Amount */}
                                <div className="flex flex-col items-center">
                                    <div className="text-gray-400 text-xs">Amount</div>
                                    <div className="text-white text-sm font-semibold">{item.profitLoss}</div>
                                </div>

                                {/* Total Balance */}
                                <div className="flex flex-col items-center">
                                    <div className="text-gray-400 text-xs">Total balance</div>
                                    <div className="text-white text-sm font-semibold">{item.balanceAmount}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='userDataTableHeader'>P/L</th>
                                <th className='userDataTableHeader'>Date & Time</th>
                                <th className='userDataTableHeader'>Amount</th>
                                <th className='userDataTableHeader'>Total balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <div className="skeleton">
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                    <div className="skeleton-line"></div>
                                </div>
                            ) : (
                                currentRecords.map((item, index) => (
                                    <tr key={index} className='text-white items-center'>
                                        <td data-label="P/L">
                                            <button className={`text-lg gradientbutton ${item.profitLoss[0] === '+' ? 'profit-gradient' : 'loss-gradient '}`}>{item.profitLoss[0] === '+' ? 'Profit' : 'Loss'}
                                            </button>
                                        </td>
                                        <td data-label="updatedOn" className="text-white text-lg">{item.updatedOn.slice(11)} <br></br> {item.updatedOn.slice(0, 10)}</td>
                                        <td data-label="Profit//Loss Value" className={`text-lg text-white`}>{item?.profitLoss}</td>
                                        <td data-label="Balance Amount" className="text-white text-lg">{item.balanceAmount}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>}
            </div>
        );
    }

    const renderInquiryTableData = () => {

        if (isMobile) {
            // Mobile View
            return (
                <>
                    <div className="flex items-center space-x-4 px-2 py-4 justify-between">
                        <h1 className="text-white text-lg font-semibold">Inquiries</h1>
                        <div className="flex flex-1 justify-end gap-2">
                            <div className="flex items-center space-x-2 bg-[#202F4C] rounded-full px-4 py-2 text-gray-300">
                                <FaSearch />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="bg-transparent outline-none text-gray-300 placeholder-gray-300"
                                />
                            </div>

                            <button className="flex items-center justify-center w-12 h-12 text-gray-300">
                                <img src={filterIcon} alt="filter" />
                            </button>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {currentUserDetails?.map((item, index) => (
                            <div
                                key={index}
                                className="bg-[#202F4C] p-4 rounded-2xl text-white space-y-2 shadow-md relative"
                            >
                                {/* Actions */}
                                <div className="flex justify-end mt-2">
                                    <ActionButtons item={item} index={index} />
                                </div>
                                {/* Contact Info */}
                                <div className="flex justify-between items-center">
                                    <div>
                                        <div>{item.email}</div>
                                        <div>{item.phone}</div>
                                    </div>
                                    <button className="bg-[#33CCCC] text-white px-3 py-1 rounded-full">
                                        {item.response}
                                    </button>
                                </div>

                                {/* Date */}
                                <div className="flex justify-between">
                                    <div className="text-[#33CCCC] text-sm">Date</div>
                                    <div className="text-lg">{item.date}</div>
                                </div>
                                {/* Message */}
                                <div className="text-[#33CCCC] text-sm">Message</div>
                                <div className="text-white">
                                    <ClientMessage content={item.clientMessage} />
                                </div>


                            </div>
                        ))}
                    </div>
                </>

            );
        }
        else return (
            <div className="mobileTable userDataTable">
                <table className="table table-auto w-full justify-between">
                    <thead>
                        <tr>
                            <th className="userDataTableHeader">Date</th>
                            <th className="userDataTableHeader">Contact</th>
                            <th className="userDataTableHeader client-message-width">Client Message</th>
                            <th className="userDataTableHeader w-[100px] text-end">
                                <div className="flex justify-center">
                                    Status
                                </div>
                            </th>
                            <th className="userDataTableHeader w-[100px] text-end"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5">
                                    <div className="skeleton">
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            currentUserDetails.map((item, index) => (
                                <tr key={index}>
                                    <td data-label="Date" className="text-white justify-start text-lg">{item.date}</td>
                                    <td data-label="Contact" className="text-white text-lg">
                                        {item.email} <br /> {item.phone}
                                    </td>
                                    <td data-label="Client Message" className="text-white text-lg client-message-width">
                                        <ClientMessage content={item.clientMessage} />
                                    </td>
                                    <td data-label="Response" className="text-white text-lg w-[100px] text-end">
                                        <div className="flex justify-center">
                                            <button className="bg-[#33CCCC] px-4 py-1 rounded-full">
                                                {item.response}
                                            </button>
                                        </div>

                                    </td>
                                    <td data-label="Actions" className="text-white text-lg w-[100px] text-end">
                                        <ActionButtons item={item} index={index} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        );
    };


    const totalPages = Math.ceil(walletData / recordsPerPage);
    const totalPagesUser = Math.ceil(userDetails / recordsPerPageUser);

    const renderPagination = () => {
        const buttons = [];

        // Add Previous button if currentPage is greater than 1
        if (currentPage > 1) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="prev" onClick={() => handlePageChange(currentPage - 1)}>
                    <ChevronLeft color='white' />
                </button>
            );
        }

        // Calculate the starting page number for pagination
        let startPage = 1;
        if (startPage <= 0) {
            startPage = 1;
        }

        // Calculate the ending page number for pagination
        let endPage = Math.ceil(walletData?.length / recordsPerPage);
        // let endPage = startPage + 3;
        if (endPage > totalPages) {
            endPage = totalPages;
        }

        // Render pagination buttons for each page
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button className={`font-medium text-white ${currentPage === i ? 'text-[#32CCCB]' : ''}`} key={i} onClick={() => handlePageChange(i)}>{i}</button>
            );
        }

        // Add Next button if currentPage is less than totalPages
        if (currentPage < totalPages) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="next" onClick={() => handlePageChange(currentPage + 1)}>
                    <ChevronRight color='white' />
                </button>
            );
        }

        return buttons;
    }

    const renderPaginationUser = () => {
        const buttons = [];

        // Add Previous button if currentPage is greater than 1
        if (currentPageUser > 1) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="prev" onClick={() => handlePageChangeUser(currentPageUser - 1)}>
                    <ChevronLeft color='white' />
                </button>
            );
        }

        // Calculate the starting page number for pagination
        let startPage = 1;
        if (startPage <= 0) {
            startPage = 1;
        }

        // Calculate the ending page number for pagination
        let endPage = Math.ceil(userDetails?.length / recordsPerPageUser);
        if (endPage > totalPagesUser) {
            endPage = totalPagesUser;
        }

        // Render pagination buttons for each page
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button className={`font-medium text-white ${currentPageUser === i ? 'text-[#32CCCB]' : ''}`} key={i} onClick={() => handlePageChangeUser(i)}>{i}</button>
            );
        }

        // Add Next button if currentPage is less than totalPages
        if (currentPageUser < totalPagesUser) {
            buttons.push(
                <button className='font-medium text-[#32CCCB]' key="next" onClick={() => handlePageChangeUser(currentPageUser + 1)}>
                    <ChevronRight color='white' />
                </button>
            );
        }

        return buttons;
    }

    // Render wallet data based on the current page
    const currentRecords = walletData.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
    const currentUserDetails = userDetails.slice((currentPageUser - 1) * recordsPerPageUser , currentPageUser  * recordsPerPageUser );


    // Profit Loss Filter States START
    const [selectedFitlerValue, setSelectedFitlerValue] = useState('recent')

    const handleFilterChange = (value) => {
        console.log(value)
        setSelectedFitlerValue(value)
    }
    const fetchData = async (selectedFitlerValue) => {
        try {
            let token = Cookies.get('token');
            let headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.get(`${process.env.REACT_APP_BACKEND}getUserProfile?timeframe=${selectedFitlerValue}`, { headers: headers });
            const data = response.data;

            console.log(data.stats)


            // setData(chartData);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    }

    useEffect(() => {
        if (selectedFitlerValue) {
            fetchData(selectedFitlerValue)
        }
    }, [selectedFitlerValue])
    // Profit Loss Filter States END


    return (
        isOpenResponse ? 
        <InquiryResponse data={selectedInquiryData} /> :
        <div
            className="gap-0 rounded-2xl"
            style={{
                overflow: 'hidden',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <p className="font-semibold text-2xl capitalize text-white text-center md:text-start">
                Hello <span className="text-[#3CC8C8]">{getFirstName()}</span>
            </p>
            <div className='mt-5 border-b border-b-[#152445] flex flex-col md:flex-row md:gap-5 justify-between items-center w-full bg-[#212F4C] py-5 md:p-5 rounded-t-2xl'>
                <div className="flex flex-col md:flex-row items-center gap-8 md:gap-16 text-[#FFFFFF] text-2xl font-semibold p-16">
                    <div className="letter-container">{userName && userName[0].toUpperCase()}</div>
                    <span className="capitalize md:text-5xl">{userName}</span>
                </div>
                <div className="button-container md:pe-4">
                    <button type="button" className="addFundsBtn text-xs md:text-lg" onClick={onAddFunds}>
                        <img src={Plus} alt="plus" className="h-3 md:h-7" />
                        Add Funds
                    </button>
                    <button type="button" onClick={openResetPopup} className="loginReset text-xs md:text-lg">Password Reset</button>
                </div>
            </div>

            {/* Add Funds */}
            <AddFunds
                addFunds={addFunds}
                setAddFunds={setAddFunds}
                openAdd={openAdd}
                onCloseAdd={onCloseAdd}
                loading={loading}
                onSave={addFundAction}
            />

            {/* Reset Password */}
            {isResetVisible && <ResetPasswordPopup onClose={closeResetPopup} />}


            <div className="mb-5 bg-[#202F4C] p-5 rounded-b-2xl text-white flex flex-col space-y-3 md:space-y-0 md:grid md:grid-cols-4 gap-6">
                {/* Left Side: Current Balance and Bottom Cards */}
                <div className="space-y-3 col-span-1">
                    <div className="flex flex-col gap-4 md:h-full p-0">
                        {/* Current Balance Card */}
                        <div className="bg-[#37435E] rounded-2xl p-6 text-center md:flex md:flex-col md:justify-between space-y-4 md:h-full">
                            <div className="text-sm md:text-lg font-semibold md:text-start">Current Balance</div>
                            <div className="flex flex-col">
                                <div className="text-4xl font-bold text-[#37D7D7] md:text-end my-2">{totalBalance}</div>
                                <div className="text-sm md:text-end">Previous Balance <span className="text-white">{previousBalance}</span></div>
                            </div>
                        </div>

                        {/* Initial Investment and Profit/Loss */}
                        <div className="flex space-x-4 md:flex-col md:space-x-0 md:space-y-4 md:h-full">
                            {/* Initial Investment */}
                            <div className="bg-[#37435E] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col md:justify-between">
                                <div className="text-sm md:text-lg font-semibold md:text-start">Initial Investment</div>
                                <div className="text-md md:text-xl font-bold text-[#37D7D7] md:text-end">{'N/A'}</div>
                            </div>

                            {/* Profit or Loss */}
                            <div className="bg-[#37435E] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col md:justify-between">
                                <div className="text-sm md:text-lg font-semibold md:text-start">Profit or Loss</div>
                                <div className={`text-xs font-bold  md:text-end py-2`}>
                                    <select
                                        value={selectedValue}
                                        onChange={handleDropdownChange}
                                        className='bg-[#212C45] outline-none text-white p-2 px-4 rounded-xl border-px border-[#212C45]'>
                                        <option value="recent" selected>Last Transcation</option>
                                        <option value="today">Today</option>
                                        <option value="1 week">1 week</option>
                                        <option value="1 month">1 month</option>
                                    </select>
                                </div>
                                <div className={`text-md md:text-xl font-bold  md:text-end
                                                ${PNL && numericValue(PNL) >= 0 ? 'amount blue' : 'amount red'}
                                            }`}>{PNL || 'N/A'}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Center: Bar Plot */}
                <div className="md:col-span-2 md:flex md:justify-center md:w-full">
                    {isMobile ? <BarPlot height={270} width={320} setSelectedChartDropdown={setSelectedChartDropdown} /> : <BarPlot height={350} width={750} setSelectedChartDropdown={setSelectedChartDropdown} />}
                </div>

                {/* Right Side: Open Trades and Cycle */}
                <div className="col-span-1">
                    <div className="flex space-x-4 md:flex-col md:space-x-0 md:space-y-4 md:h-full">
                        <div className="bg-[#37435E] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col justify-between md:h-full">
                            <div className="text-sm md:text-lg font-semibold text-start">Open Trades</div>
                            <div className="text-4xl text-end font-bold text-white">{openTrades || 'NA'}</div>
                        </div>

                        <div className="bg-[#37435E] rounded-2xl p-4 text-center space-y-2 w-full md:flex md:flex-col justify-between md:h-full">
                            <div className="text-sm md:text-lg font-semibold text-start">Cycle</div>
                            <div className="md:flex md:flex-col">
                                <div className="text-4xl text-end font-bold text-white">{subscriptionPeriod}</div>
                                <div className="text-sm text-end text-white/70">days</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-5 table-form-container rounded-2xl flex-wrap md:flex-nowrap'>
                <div className='userTable rounded-2xl w-full mb-5 md:mb-0 p-5'>
                    <div className='p-0 bg-[#202F4C] mb-5'>
                        <div className='flexCol' style={{ alignItems: 'flex-start', display: 'flex', gap: '1em' }}>
                            <WalletActivityHeader />
                            <div className="table-btn-container">
                                <button className='AddUserButton'>Recent Transactions</button>
                                <button className='AddUserButton'>Upcoming Transactions</button>
                            </div>
                        </div>
                    </div>

                    {
                        renderWalletTable()
                    }
                    <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                        <div>
                            <span className='paginationResultText'>
                                Showing {(currentPage - 1) * 10 + 1} to {currentPage * 10 > walletData.length ? walletData.length : currentPage * 10} of {walletData.length} transactions
                            </span>
                        </div>
                        <div className='flexRow' style={{ gap: 16 }}>
                            {
                                renderPagination()
                            }
                        </div>
                    </div>
                </div>
                {
                    !isEdit ?
                        <div className="form-container bg-[#202F4C] p-5" style={{ backgroundColor: '#202F4C' }}>
                            <div className="record-header pe-3 md:pe-0">
                                <div className="record-title text-md md:text-xl">User Information</div>
                                <button onClick={() => { setEdit(true) }}
                                    className="py-2 px-4 text-lg text-white rounded-full md:w-[100px]"
                                    style={{
                                        background: 'linear-gradient(90deg, #D724FC 12%, #7770FF 100%)'
                                    }}
                                >Edit</button>
                            </div>
                            <div className="md:mt-[5rem]">
                                <div className="details-header  hidden md:block" style={{ color: ' #3CC8C8' }}>
                                    Full Name
                                </div>
                                <div className="details-value" style={{ color: '#3CC8C8' }}>
                                    {name || ''}
                                </div>
                            </div>
                            <div>
                                <div className="details-header">
                                    Email Address
                                </div>
                                <div className="details-value text-md md:text-lg">
                                    {email || ''}
                                </div>
                            </div>
                            <div>
                                <div className="details-header">
                                    Phone Number
                                </div>
                                <div className="details-value text-md md:text-lg">
                                    {phoneNumber || ''}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="form-container bg-[#202F4C] p-5" style={{ backgroundColor: '#202F4C' }}>
                            <div className="record-header">
                                <div className="record-title text-md md:text-xl">User Information</div>
                                <button onClick={() => { setEdit(false) }} className="text-slate-300"><Close /></button>

                                {/* <button className="edit-btn">Edit</button> */}
                            </div>
                            <div className="md:h-full md:flex md:flex-col md:justify-center gap-3 md:space-y-6">
                                <div className='input-container'>
                                    <span className='block text-sm text-[#3CC8C8] mb-2'>FullName</span>
                                    <input type='text' value={name} placeholder="Full Name" className='adduserInput'
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setErrorMessage('');
                                        }
                                        }
                                    />
                                </div>

                                <div className='input-container'>
                                    <label className="block text-sm text-[#3CC8C8] mb-2">Email Address</label>
                                    <input type="email" value={email} placeholder="Email address" className="adduserInput"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setErrorMessage('');
                                        }}
                                    />
                                </div>

                                <div className='input-container'>
                                    <label className="block text-sm text-[#3CC8C8] mb-2">Phone Number</label>
                                    <div className="flex">
                                        <select className="adduserInput dropdown">
                                            <option>+1</option>
                                        </select>
                                        <input type="tel" value={phoneNumber} placeholder="000 123 003" className="adduserInput phone"
                                            onChange={(e) => {
                                                setPhoneNumber(e.target.value);
                                                setErrorMessage('');
                                            }} />
                                    </div>
                                </div>
                                <div className="btn-container mt-5">
                                    {
                                        loading ?
                                            <div className='AddUserButton'>
                                                <ButtonLoader />
                                            </div>
                                            :
                                            <div>
                                                <button onClick={handleSave}
                                                    className='AddUserButton'>Save</button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>

            <div className="mt-5 inquiry-table-container md:bg-[#202F4C] md:p-5 md:rounded-2xl">
                <div className="record-header hidden md:flex">
                    <span class="record-title hidden md:block">Inquiries</span>
                    <div className='topfeatures'>
                        <div className='relative'>
                            <Search className='absolute left-3 top-[20px]' size={18} color='#D6DAE0' />
                            <input
                                style={{ fontSize: '1rem' }}
                                className='ps-10 pe-4 text-md py-2 rounded-full text-[#D6DAE0] bg-[#37445E] outline-none'
                                onKeyDown={handleSearchKeyPress}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder='Search'
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <Dropdown />
                        </div>
                    </div>
                </div>
                {renderInquiryTableData()}
                <div className='flexRow bg-[#202F4C]' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 24 }}>
                        <div>
                            <span className='paginationResultText'>
                                Showing {(currentPageUser - 1) * 10 + 1} to {currentPageUser * 10 > userDetails.length ? userDetails.length : currentPageUser * 10} of {userDetails.length} Inquiries
                            </span>
                        </div>
                        <div className='flexRow' style={{ gap: 16 }}>
                            {
                                renderPaginationUser()
                            }
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default UserDashBoardAdminView;
