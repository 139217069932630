import Cookies from 'js-cookie';
import TokenParser from '../Helpers/tokenParser';


export const getUserData = () => {
  let token = Cookies.get('token');
  let details = TokenParser(token);
  let data = details.payload;
  if (data) {
      return data
  }
  return null
}


export const getUserName = () => {
    let token = Cookies.get('token');
    let details = TokenParser(token);
    let name = details.payload?.username;
    if (name) {
        return name
    }
    return null
}

export const getFirstName = () => {
  let token = Cookies.get('token');
  let details = TokenParser(token);
  let name = details.payload?.username?.split(' ')?.[0];
  if (name) {
      return name + '!'
  }
  return null
}

export function calculateTotals(users) {
    return users.reduce(
      (totals, user) => {
        // Convert balance and profitLossAmount to numbers
        const balance = parseFloat(user?.balance?.replace(/[^0-9.-]+/g, '')) || 0;
        const profitLoss = parseFloat(user?.profitLossAmount?.replace(/[^0-9.-]+/g, '')) || 0;
        
        // Add to totals based on profit/loss type
        totals.totalProfitLoss += user.profitLossType === 'Profit' ? profitLoss : -profitLoss;
        totals.totalBalance += balance;
        totals.totalOpenTrades += user.openTrades || 0;
  
        return totals;
      },
      { totalProfitLoss: 0, totalBalance: 0, totalOpenTrades: 0 }
    );
  }

  export const numericValue = (value) => parseFloat(value?.replace(/[^0-9.]/g, ''))
