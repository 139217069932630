import NewsFeed from "./Market/NewsFeed";
import React, { useEffect, useState } from 'react';
// news feed images
import img1 from '../assets/images/market/1.png'
import img2 from '../assets/images/market/2.png'
import img3 from '../assets/images/market/3.png'
import { getFirstName } from "../services/common.utils";
import { useNavigate } from "react-router-dom";
import { articles } from "./Market/MarketDetails";
import NewsSearchSort from "./Market/NewsSearchSort";


const News = () => {

    const navigate = useNavigate();

    return (
        <div>
            <p className='md:hidden font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>
            <div className='md:hidden'>
                <NewsSearchSort />
            </div>
            <NewsFeed title={'News'} navigate={navigate} articles={articles} />
        </div>
    )
}

export default News;