import moment from 'moment-timezone';
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "../Styles/Dashboard.css";
import { capitalize } from "@mui/material";
import { Search } from 'lucide-react';
import biticon from '../assets/images/trade/biticon.svg';
import litecoin from '../assets/images/trade/litecoin.svg';
import ethereum from '../assets/images/trade/ethereum.svg';
import solana from '../assets/images/trade/solana.svg';
import RedDownIcon from '../assets/images/trade/reddownicon.svg';
import GreenUpIcon from '../assets/images/trade/greepupicon.svg';
import TrendImage from '../assets/images/trade/sampleTrendingImage.svg'
import { getFirstName } from '../services/common.utils';
// import sampleData from '../Helpers/sampleMarketData.json'
import graph from '../assets/images/market/graph.svg'
import btcIcon from '../assets/images/btc.svg'

const Trade = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const tredingCoins = [
    {
      name: 'Bitcoin',
      image: require('../assets/images/trade/biticon.svg').default,
      code: 'BTC',
      amount: '$52,291',
      increment: '25.90',
      decrement: '',
    },
    {
      name: 'Litecoin',
      code: 'ITC',
      image: litecoin,
      amount: '$52,291',
      increment: '25.90',
      decrement: '',
    },
    {
      name: 'Ethereum',
      code: 'ETH',
      image: ethereum,
      amount: '$52,291',
      increment: '25.90',
      decrement: '',
    },
    {
      name: 'Solana',
      image: solana,
      code: 'SOL',
      amount: '$52,291',
      increment: '',
      decrement: '25.90',
    },
    {
      name: 'Bitcoin',
      image: require('../assets/images/trade/biticon.svg').default,
      code: 'BTC',
      amount: '$52,291',
      increment: '25.90',
      decrement: '',
    },
  ]

  const trendingCards = [
    {
      image: TrendImage,
      postedBefore: '13m ago',
      tagLine: 'Bitcoin Maintains Stability While Altcoins Rally'
    },
    {
      image: TrendImage,
      postedBefore: '13m ago',
      tagLine: 'Bitcoin Maintains Stability While Altcoins Rally'
    },
    {
      image: TrendImage,
      postedBefore: '13m ago',
      tagLine: 'Bitcoin Maintains Stability While Altcoins Rally'
    },
    {
      image: TrendImage,
      postedBefore: '13m ago',
      tagLine: 'Bitcoin Maintains Stability While Altcoins Rally'
    },
    {
      image: TrendImage,
      postedBefore: '13m ago',
      tagLine: 'Bitcoin Maintains Stability While Altcoins Rally'
    }
  ]

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const TrendingCoin = (coin) => {
    // console.log('coin', coin)
    return (
      <div className='flex flex-col min-w-[300px] w-full bg-[#212F4C] p-3 rounded-xl gap-4'>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row gap-4'>
            <div className='bg-[#31353F] p-2'>
              <img src={coin.coin.image} alt='img' />
            </div>
            <div>
              <div className='flex flex-col'>
                <span className='text-[#FFF] cursor-pointer' onClick={() => navigate('/admin/market/'+coin.coin.id)}>{coin.coin.name}</span>
                <span className='text-[#FFF]'>{coin.coin.code}</span>
              </div>
            </div>
          </div>
          <div>
            <img src={GreenUpIcon} alt='up' />
          </div>
        </div>
        <div>
          <div className='text-[#FFF] font-bold text-xl'>{coin.coin.amount}</div>
        </div>
        <div>
          {
            coin.coin.increment ?
              <span className='text-[#1ECB4F]'> + {coin.coin.increment}</span>
              : <span className='text-[#F46D22]'> - {coin.coin.decrement}</span>
          }
        </div>
      </div>
    )
  }

  const TrendingCard = (card) => {
    return (
      <div className='flex flex-col min-w-[33%] md:min-w-0 w-full bg-[#212F4C] p-3 rounded-xl gap-4'>
        <div>
          <img src={card.card.image} className='h-16 md:h-auto' alt='card img' />
        </div>
        <div>
          <span className='text-[#FFF] text-xs'>{card.card.postedBefore}</span>
        </div>
        <div>
          <span className='text-[#FFF] text-xs md:text-lg'>{card.card.tagLine}</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setLoading(true);
    axios.get('https://fcsapi.com/api-v3/crypto/supply?sort=rank&order=ASC&limit=30&access_key=4g42X5Wkupqhbfd4jWlGoMp&timestamp==' + Date.now())
      .then((response) => {
        setData(response.data.response);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error)
      })
  }, [])

  const renderTableData = () => {
    // let renderData = searchValue ? searchUsers(searchValue) :
    // 				currentPage ? data.slice(((currentPage-1)*5), (currentPage*5)) : data;
    return (
      <div className='mobileTable userDataTable'>
        {
          true ? (
            <div className="flex flex-col space-y-4">
              {loading ? (
                <div className="skeleton space-y-2">
                  {[...Array(8)].map((_, idx) => (
                    <div key={idx} className="skeleton-line h-4 bg-gray-300 rounded-md"></div>
                  ))}
                </div>
              ) : (
                data?.map((item, index) => (
                  <div
                    key={index}
                    className="bg-[#212F4C] p-4 rounded-lg shadow-md text-white cursor-pointer md:hidden" // Only shows on mobile
                    onClick={() => navigate('/admin/market/' + item.rank)}
                  >
                    <div className="flex justify-between items-center mb-4">
                      <div className='flex gap-2'>
                        <img src={btcIcon} alt='btc' />
                        <div className='flex flex-col gap-1'>
                          <div className="text-lg font-bold">{item.name} / {item.symbol}</div>
                          <p className='text-sm'>Today up to <span className='text-[#37CBB0]'>+ 5.34%</span></p>
                        </div>
                      </div>
                      <div>
                        {/* Optional: Add icon or chart representation here */}
                        <img src={graph} alt='graph' />
                      </div>
                    </div>
                    <div className="space-y-4 p-3">
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Change</span>
                        <span className={`text-lg ${item.quote?.USD?.percentage_change_24h < 0 ? 'text-red-500' : 'text-teal-400'}`}>
                          {item.quote?.USD?.percentage_change_24h > 0 ? '+' : ''}{item.quote?.USD?.percentage_change_24h}%
                        </span>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Volume</span>
                        <span>{item?.quote?.USD?.vol_24H ? item?.quote?.USD?.vol_24H : 'N/A'}</span>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Market Cap</span>
                        <span>{item.quote?.USD?.market_cap ? item.quote?.USD?.market_cap : 'N/A'}</span>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <span className="text-[#9197A4]">Price</span>
                        <span>{item.quote?.USD?.price || 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

          ) :
            <table className="table">
              <thead>
                <tr>
                  <th className='userDataTableHeader text-start'>Name</th>
                  <th className='userDataTableHeader'>Symbol</th>
                  <th className='userDataTableHeader'>Price</th>
                  <th className='userDataTableHeader'>Last 1hr(%)</th>
                  <th className='userDataTableHeader'>Last 24hr(%)</th>
                  <th className='userDataTableHeader'>Last 1week(%)</th>
                  <th className='userDataTableHeader'>Volume in 24hr</th>
                  <th className='userDataTableHeader'>Market Cap</th>
                  {/* <th className='userDataTableHeader'>Remove</th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="skeleton">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div><div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  data?.map((item, index) => (
                    <tr key={index} className='text-white cursor-pointer' onClick={() => { navigate('/admin/market/' + item.rank) }}> {/* Make sure to add a key here */}
                      <td
                        data-label="Name" className="text-white cursor-pointer text-start text-lg">{item.name}
                      </td>
                      <td data-label="Symbol" className="text-white text-lg">{item.symbol}</td>
                      <td data-label="Price" className="text-white text-lg">{item.quote?.USD?.price}</td>
                      <td data-label="Last 1hr(%)" className={`text-lg ${item.quote?.USD?.percentage_change_1h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                        {item?.quote?.USD?.percentage_change_1h > 0 ? '+' + item?.quote?.USD?.percentage_change_1h : item?.quote?.USD?.percentage_change_1h}
                      </td>
                      <td data-label="Last 24hr(%)" className={`text-lg ${item.quote?.USD?.percentage_change_24h < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                        {item?.quote?.USD?.percentage_change_24h > 0 ? '+' + item?.quote?.USD?.percentage_change_24h : item?.quote?.USD?.percentage_change_24h}
                      </td>
                      <td data-label="Last 1week(%)" className={`text-lg ${item.quote?.USD?.percentage_change_7d < 0 ? 'text-[#FD3549]' : 'text-[#3CC8C8]'}`}>
                        {item?.quote?.USD?.percentage_change_7d > 0 ? '+' + item?.quote?.USD?.percentage_change_7d : item?.quote?.USD?.percentage_change_7d}
                      </td>
                      <td data-label="Volume in 24hr" className="text-white text-lg">
                        {item?.quote?.USD?.vol_24H ? item?.quote?.USD?.vol_24H : 0}
                      </td>
                      <td data-label="Market Cap" className="text-white text-lg">
                        {item.quote?.USD?.market_cap ? item.quote?.USD?.market_cap : 0}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>}
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-row justify-between items-center w-full'>
        <p className='hidden md:block font-semibold text-2xl capitalize text-white text-center md:text-start'>
          Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
        </p>
        <div className='hidden md:block md:relative'>
          <Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
          <input
            className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
            // onKeyDown={handleSearchKeyPress}
            // onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Search'
          />
        </div>
      </div>
      <div className='w-full space-y-3'>
        <p className='md:hidden font-semibold text-2xl capitalize text-white text-center md:text-start'>
          Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
        </p>
        <div className='flex flex-row justify-between items-center w-full'>
          <span className='text-[#FFF] text-sm mt-4 mb-4'>TOP TRENDING COINS</span>
          <div className='md:hidden block relative'>
            <Search className='absolute left-3 top-2.5' size={18} color='#D6DAE0' />
            <input
              className='ps-10 pe-4 py-2 rounded-full text-[#D6DAE0] bg-[#37445E]'
              placeholder='Search'
            />
          </div>
        </div>
        <div className='flex flex-row flex-nowrap gap-4 overflow-x-auto md:overflow-hidden scrollbar-none'>
          {
            tredingCoins?.map((coin) => {
              return (
                <TrendingCoin coin={coin} />
              )
            })
          }
        </div>
        <div className='flex flex-row flex-nowrap gap-4 overflow-x-auto md:overflow-hidden scrollbar-none'>
          {
            trendingCards?.map((card) => {
              return (
                <TrendingCard card={card} />
              )
            })
          }
        </div>
        <div className='md:bg-[#212F4C] rounded-xl'>
          <div className='p-4 py-4 flex justify-between'>
            <span className='text-[#FFF] text-xl '>Market</span>
            <button className='text-[#E4E4E4] text-md md:hidden'>View More</button>
          </div>
          {
            renderTableData()
          }
        </div>
      </div>
    </div>
  )
}

export default Trade;