import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../../Styles/AddUser.css'
import Cookies from 'js-cookie';
import { Eye, EyeOff, X } from 'lucide-react';
import { getFirstName } from '../../services/common.utils';
import { toast } from 'react-toastify';

const ResetPasswordUser = () => {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const validateForm = () => {
        if (!currentPassword.trim()) {
            setErrorMessage('Current password is required.');
            return false;
        }
        if (!newPassword.trim()) {
            setErrorMessage('New password is required.');
            return false;
        }
        if (newPassword.length < 6) {
            setErrorMessage('New password must be at least 6 characters.');
            return false;
        }
        if (newPassword !== confirmPassword) {
            setErrorMessage('New password and confirm password must match.');
            return false;
        }
        return true;
    };

    const handleResetPassword = () => {
        setErrorMessage('');

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        let apiRequest = {
            "isNewUser": "True",
            "oldPassword": currentPassword,
            "newPassword": newPassword
        };

        axios.put(`${process.env.REACT_APP_BACKEND}updatePassword`, apiRequest, { headers: headers })
            .then((apiResponse) => {
                toast.success('Profile updated successfully!', { theme: 'dark' });

                setLoading(false);
                navigate('/profile');
            })
            .catch((apiError) => {
                setErrorMessage(apiError?.response?.data?.message || 'Internal server error');
                setLoading(false);
                if (apiError.response?.status === 401) {
                    navigate('/login');
                }
            });
    };

    return (
        <div className="h-full text-white md:p-6 md:h-screen">
            <p className='mb-10 font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{getFirstName()}</span>
            </p>

            <div className="max-w-2xl mx-auto">
                <div className="bg-[#212F4C] rounded-2xl shadow-lg">
                    <div className="p-8 flex justify-between items-center mb-8 border-b-2 border-b-[#152445]">
                        <h2 className="text-2xl font-medium">Reset Password</h2>
                        <button className="text-[#3CC8C8] hover:text-white">
                            <X size={24} onClick={() => navigate('/profile')} />
                        </button>
                    </div>
                    <div className="p-8 pt-0 space-y-6">
                        {/* Current Password */}
                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Current Password</label>
                            <div className="relative">
                                <input
                                    type={showCurrentPassword ? "text" : "password"}
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="Enter current password"
                                    className="w-full bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                                <span
                                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-[#3CC8C8]"
                                >
                                    {showCurrentPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                </span>
                            </div>
                        </div>

                        {/* New Password */}
                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">New Password</label>
                            <div className="relative">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="Enter new password"
                                    className="w-full bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                                <span
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-[#3CC8C8]"
                                >
                                    {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                </span>
                            </div>
                        </div>

                        {/* Confirm Password */}
                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Confirm Password</label>
                            <div className="relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="Confirm new password"
                                    className="w-full bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                                <span
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-[#3CC8C8]"
                                >
                                    {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                </span>
                            </div>
                        </div>

                        {/* Error Message */}
                        {errorMessage && (
                            <div className="text-red-500 text-sm">{errorMessage}</div>
                        )}

                        {/* Reset Password Button */}
                        <button
                            onClick={handleResetPassword}
                            disabled={loading}
                            style={{
                                background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
                            }}
                            className="w-fit px-12 mx-auto text-white rounded-full py-4 font-medium hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
                        >
                            {loading ? (
                                <>
                                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                    <span>Please wait ...</span>
                                </>
                            ) : (
                                <>
                                    <span>Reset Password</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ResetPasswordUser