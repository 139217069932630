import React, { useEffect, useState } from 'react'
import TokenParser from '../Helpers/tokenParser';
import Cookies from 'js-cookie';
import QuickAnalytics from '../Components/Analytics/QuickAnalytics';
import ActiveTradeStats from '../Components/ActiveTrades/ActiveTradeStats';
import UsersList from './Users/UsersList';
import { calculateTotals } from '../services/common.utils';

function DashboardHome() {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false)
    const [cardsData, setCardData] = useState([
        {
            title: 'Total Trades',
            value: '',
            change: '0',
            bgColor: 'bg-teal-400',
            textColor: 'text-white',
            onClick: () => console.log('Volume card clicked'),
            isActive: true
        },
        {
            title: 'Total Profit / Loss',
            value: '0',
            change: '0',
            bgColor: 'bg-[#7E6CFF]',
            textColor: 'text-white',
            onClick: () => console.log('Revenue card clicked'),
            hasFilter: true
        },
        {
            title: 'Open Trades',
            value: '0',
            change: '0',
            bgColor: 'bg-[#D02AFD]',
            textColor: 'text-white',
            onClick: () => console.log('Users card clicked'),
        },
    ]);

    const [analyticsStat, setAnalyticsStat] = useState({
        totalUsers: { count: 89, growth: 24 },
        pendingInquiries: { count: 52, growth: 24 },
        tradingUsers: { count: 241 },
    });

    useEffect(() => {
        let token = Cookies.get('token');
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setName(firstLetter)
        }
    }, [])

    // update Analytics/Card data
    const updateStat = (data) => {
        let modified_data = {
            ...analyticsStat,
            totalUsers: {
                count: data?.recordsFiltered,
                growth: 0
            },
        }
        setAnalyticsStat(modified_data)

        const totals = calculateTotals(data?.users);

        setCardData((prevData) =>
            prevData.map((card) => {
                if (card.title === 'Total Trades') {
                    return { ...card, value: `$${totals.totalBalance.toFixed(2)}` };
                } else if (card.title === 'Total Profit / Loss') {
                    return { ...card, value: `$${totals.totalProfitLoss.toFixed(2)}` };
                } else if (card.title === 'Open Trades') {
                    return { ...card, value: totals.totalOpenTrades.toString() };
                }
                return card;
            })
        );
    }

    // render
    return (
        <div className='h-full'>
            <p className='font-semibold text-2xl capitalize text-white text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{name}</span>
            </p>

            {/* Charts / Active Trades */}
            <div className="grid grid-cols-1 md:grid-cols-10 gap-5 mt-10">
                <div className="md:col-span-7 col-span-1">
                    {/* QuickAnalytics */}
                    <QuickAnalytics
                        // chartData={chartData}
                        stats={cardsData}
                        analyticsStat={analyticsStat} />
                </div>

                <div className="md:col-span-3 col-span-1">
                    {/* ActiveTradeStats */}
                    <ActiveTradeStats />
                </div>
            </div>

            {/* Users */}
            <div className='mt-10'>
                <UsersList updateAnalyticsStat={updateStat} />
            </div>

        </div>
    )
}

export default DashboardHome