import React, { useState } from 'react';

const DropdownRadio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { id: 'Default', label: 'Default' },
    { id: 'Latest', label: 'Latest' },
    { id: 'Profit (high to low)', label: 'Profit (high to low)' },
    { id: 'Balance (high to low)', label: 'Balance (high to low)' },
    { id: 'Deleted', label: 'Deleted' },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (optionId) => {
    setSelectedOption(optionId);
    setIsOpen(false); // close dropdown after selection
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-[#37445E] rounded-full shadow-sm"
      >
        {selectedOption ? options.find((opt) => opt.id === selectedOption).label : 'Sort by'}
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-[#38455F] rounded-md shadow-lg ring-1 ring-[#28274D] focus:outline-none">
          <div className="p-2">
            {options.map((option) => (
              <label key={option.id} className="flex items-center px-2 py-1 cursor-pointer">
                <input
                  type="radio"
                  name="options"
                  value={option.id}
                  checked={selectedOption === option.id}
                  onChange={() => handleSelect(option.id)}
                  className="form-radio h-4 w-4 text-green-500 peer-active:text-gray-300"
                />
                <span className="ml-2 text-white">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownRadio;
