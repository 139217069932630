import React, { useEffect, useState } from 'react'
import UserInfoCard from './UserInfoCard'
import { getUserData } from '../../services/common.utils'
import BalanceChart from './BalanceChart'
import BotPerformanceCard from './BotPerformanceCard'
import dummyTrade from '../../assets/images/user/dummyTrade.png'
import WalletActivity from '../../Components/Analytics/WalletActivity'
import Cookies from 'js-cookie'
import TokenParser from '../../Helpers/tokenParser'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import BalanceCard from '../../Components/Common/BalanceCard'
import HangingHistogram from '../../Components/BarPlot'

function UserDashboard() {
    const navigate = useNavigate();

    const userData = getUserData()
    const botperformanceData = [
        { title: 'Etherium', link: '', img: dummyTrade },
        { title: 'Bitcoin', link: '', img: dummyTrade },
    ]

    const [walletData, setWalletData] = useState([])
    const [loading, setLoading] = useState(false)
    const [adminName, setAdminName] = useState('');
    const [userName, setUserName] = useState('');
    const [PNLType, setPNLType] = useState("");
    const [totalBalance, setTotalBalance] = useState(0);
    const [updatedAt, setUpdatedAt] = useState("");
    const [previousBalance, setPreviousBalance] = useState("");
    const [openTrades, setOpenTrades] = useState(0);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState(0);
    const [openTrade, setOpenTrade] = useState(false);
    const [PNL, setPNL] = useState(0);
    const [userId, setUserId] = useState(null)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)

    let token = Cookies.get("token");
    let dummyData = [
        {
            "balance": 0,
            "balanceAmount": "+$61,640.5",
            "profitLoss": "+$327.09",
            "updatedOn": "10-23-2024T05:06:27 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$61,313.41",
            "profitLoss": "+$802.53",
            "updatedOn": "10-23-2024T12:17:09 AM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$60,510.88",
            "profitLoss": "+$3,725.06",
            "updatedOn": "10-20-2024T03:19:41 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$56,785.82",
            "profitLoss": "+$566.02",
            "updatedOn": "10-16-2024T05:59:52 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$56,219.8",
            "profitLoss": "+$351.86",
            "updatedOn": "10-15-2024T05:46:32 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$55,867.95",
            "profitLoss": "+$651.42",
            "updatedOn": "10-15-2024T06:41:47 AM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$55,216.52",
            "profitLoss": "+$1,137.03",
            "updatedOn": "10-14-2024T02:02:56 AM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$54,079.49",
            "profitLoss": "+$652.09",
            "updatedOn": "10-11-2024T04:41:49 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$53,427.4",
            "profitLoss": "+$751.46",
            "updatedOn": "10-09-2024T04:30:06 PM"
        },
        {
            "balance": 0,
            "balanceAmount": "+$52,675.94",
            "profitLoss": "+$203.71",
            "updatedOn": "10-08-2024T05:13:25 PM"
        }
    ]
    // fetch
    const fetchDetails = async (timeframeValue = "") => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        await axios.get(process.env.REACT_APP_BACKEND + `getUserProfile?timeframe=${timeframeValue}&id=${userId}`, {
            headers: headers,
        })
            .then((apiResponse) => {
                setUserName(apiResponse.data?.username || 0);
                setTotalBalance(apiResponse.data?.balance || 0);
                setPreviousBalance(apiResponse.data?.previousBalance || 0);
                setUpdatedAt(apiResponse.data?.lastUpdatedOn || 0);
                setPNLType(apiResponse.data?.profitLossType || 0);
                setSubscriptionPeriod(apiResponse.data?.subscriptionPeriod || 0);
                setOpenTrade(apiResponse.data?.enableTrade || 0);
                setOpenTrades(apiResponse.data?.openTrades || 0);
                setPNL(
                    apiResponse.data?.profitLossAmount
                );
            })
            .catch((apiError) => {
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });

        headers["userId"] = userId;

        await axios.get(process.env.REACT_APP_BACKEND + "history", { headers: headers, })
            .then((apiResponse) => {
                setWalletData(apiResponse.data?.stats);
                setRecordsFiltered(apiResponse.data?.recordsFiltered)
                setLoading(false);
            })
            .catch((apiError) => {
                if (apiError.response.status === 401) {
                    navigate("/login");
                }
            });
    };

    useEffect(() => {
        const leftDiv = document.querySelector('.left-div');
        const rightDiv = document.querySelector('.right-div');
        if (rightDiv && leftDiv) {
            leftDiv.style.minHeight = `${rightDiv.offsetHeight}px`;
        }
    }, [walletData]); // Update when walletData changes

    // init
    useEffect(() => {
        let details = TokenParser(token);
        let firstLetter = details.payload?.username;
        if (firstLetter) {
            setAdminName(firstLetter);
            setUserId(details.payload.userId)
            // setUserId('0021aa7dd5ab5b9e1ff171c80da4afb7690ad983485bfe616d7f0b13ae8207af')
        }

        if (userId) {
            fetchDetails();
        }
    }, [userId]);


    // conver format
    const formatCurrency = (value) => { return value ? Number(value?.toString()?.replace(/[^0-9.-]+/g, "")) : 0 }

    // render
    return (
        <div className="text-white">
            <p className='font-semibold text-2xl capitalize text-white mb-5 text-center md:text-start'>
                Hello <span className='text-[#3CC8C8]'>{userData?.username}</span>
            </p>
            {/* Info */}
            <UserInfoCard name={userData?.username} email={userData?.email} />

            {/* Balance Chart */}
            <div className='my-5'>
                <BalanceChart />
            </div>

            {/* Bot Performance */}
            <div className='flex flex-col md:flex-row w-full gap-5'>
                {botperformanceData?.map((e, index) => (
                    <div className='md:w-1/2 w-full' key={index}>
                        <BotPerformanceCard title={e.title} link={e.link} img={e.img} />
                    </div>
                ))}
            </div>

            {/* Weekly Activity / Stats */}
            <div className='flex flex-col md:flex-row gap-5 mt-5'>
                {/* left */}
                <div className='md:w-[65%] w-full left-div'>
                    <WalletActivity
                        walletData={walletData}
                        loading={loading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </div>

                {/* right */}
                <div className='md:w-[35%] w-full flex flex-col gap-4 right-div'>
                    {walletData.length === 0 ? (
                        <div className="bg-[#2C3957] rounded-2xl p-8 shadow-lg text-white h-full">
                            <div className="flex bg-transparent h-full flex-col items-center justify-center w-full py-8 md:min-h-[500px]">
                                <div className="text-2xl md:text-4xl text-[#32CCCB] font-semibold">No History Available</div>
                                <p className="text-sm md:text-lg mt-2 text-gray-400">Your transaction history will appear here.</p>
                            </div>
                        </div>
                    ) :
                        <>
                            <div className='md:w-3/3'>
                                <BalanceCard title='Balance' value={formatCurrency(totalBalance)}
                                    previousBalance={formatCurrency(previousBalance)} />
                            </div>
                            <div className='flex flex-col md:flex-row gap-4'>
                                <div className='md:w-1/2'>
                                    <BalanceCard title='Initial Investment' value={formatCurrency(openTrades)} />
                                </div>
                                <div className='md:w-1/2'>
                                    <BalanceCard title='Profit &  Loss' value={formatCurrency(PNL)} />
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row gap-4'>
                                <div className='md:w-1/2'>
                                    <BalanceCard title='Open Trades' trades={openTrades ? Number(openTrades) : 0} height={150} />
                                </div>
                                <div className='md:w-1/2'>
                                    <BalanceCard title='Cycle' days={5} height={150} />
                                </div>
                            </div>
                            {/* Chart */}
                            <HangingHistogram height={285} radius={'16px'} bg={'#2C3A58'} width={550} />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserDashboard