import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi'; // Importing an icon library
import { MdArrowDropDown } from 'react-icons/md';

const NewsSearchSort = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState('Sort By');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortToggle = () => {
    setSortOpen(!sortOpen);
  };

  const handleSortOptionClick = (option) => {
    setSortOption(option);
    setSortOpen(false);
  };

  return (
    <div className="flex space-x-4 bg-dark-blue p-4 rounded-lg">
      {/* Search Button */}
      <div className="flex items-center bg-opacity-20 bg-white rounded-full px-4 py-2 cursor-pointer text-white">
        <FiSearch className="mr-2" />
        <input 
          type="text" 
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="bg-transparent focus:outline-none text-white placeholder-gray-300"
        />
      </div>

      {/* Sort Button */}
      <div className="relative">
        <button 
          onClick={handleSortToggle}
          className="flex items-center bg-opacity-20 bg-white rounded-full px-4 py-2 cursor-pointer text-white"
        >
          <span>{sortOption}</span>
          <MdArrowDropDown className="ml-2" />
        </button>

        {/* Sort Options Dropdown */}
        {sortOpen && (
          <div className="absolute mt-2 w-full bg-dark-blue rounded-lg shadow-lg">
            <ul className="text-white">
              <li 
                onClick={() => handleSortOptionClick('Newest')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Newest
              </li>
              <li 
                onClick={() => handleSortOptionClick('Oldest')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Oldest
              </li>
              <li 
                onClick={() => handleSortOptionClick('Popular')}
                className="px-4 py-2 hover:bg-opacity-20 hover:bg-white cursor-pointer"
              >
                Popular
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};


export default NewsSearchSort