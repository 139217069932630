import React, { useEffect, useState } from 'react'
import { Link, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import cashIcon from '../../assets/images/market/casIcon.svg'
import globeIcon from '../../assets/images/market/globe.svg'
import twitterIcon from '../../assets/images/market/twitter.svg'
import fbIcon from '../../assets/images/market/fb.svg'
import starIcon from '../../assets/images/market/star.svg'
import graph from '../../assets/images/market/graph.svg'

function LeftBarMarketData({ img }) {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [cryptoData, setCryptoData] = useState({
        price: '43,386.86',
        volume: '24,723.34',
        marketCap: '$1.31T',
        fdv: '$1.39T',
        totalSupply: '19.77M BTC',
        allTimeHigh: '$73,750.07',
        allTimeLow: '$0.04865',
        rating: 4.9,
    });

    useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

    const StatItem = ({ label, value, percentage, isFull = false }) => (
        <div className='md:border-[1px] md:border-[#272337] px-5 py-2 rounded-xl w-[48%] md:w-full'
            style={{ width: isFull ? '100%' : '', textAlign: isFull ? 'center' : '' }}>
            <p className="text-slate-400 text-sm mb-1">{label}</p>
            <div className="flex items-center gap-2" style={{ justifyContent: isFull ? 'center' : '' }}>
                <span className="text-lg font-medium text-[#3CC8C8]">{value}</span>
                {percentage ? (
                    <span className="text-[#FC3549] text-sm">{percentage}</span>
                ) : (
                    <ArrowUpRight className="text-[#3BC8C7] w-8 h-7 -ml-2" />
                )}
            </div>
        </div>
    );

    const StatHighlight = ({ label, value, isFull = false }) => (
        <div className="bg-[#272337] px-3 py-2 rounded flex justify-between items-center text-sm w-[48%] md:w-full"
            style={{ width: isFull ? '100%' : '', textAlign: isFull ? 'center' : '' }}>
            <div className='flex flex-col gap-3'>
                <span className="text-slate-400">{label}</span>
                <span className='flex gap-2'>
                    <img src={cashIcon} alt='cash' />
                    {value}
                </span>
            </div>

            <select className='bg-transparent border-none focus:outline-none'>
                <option selected>USD</option>
                <option>GBP</option>
            </select>

        </div>
    );

    const Rating = ({ rating }) => (
        <div className="px-2 mt-2 flex flex-col w-full gap-4 text-[#9197A4] pb-5 border-b-[1px] border-b-[#272337]">
            <div className='flex justify-between'>
                <span className=''>Website</span>
                <img src={globeIcon} alt='globe' className='h-5 w-5' />
            </div>
            <div className='flex justify-between'>
                <span className=''>Socials</span>
                <span className='flex gap-1'>
                    <img src={twitterIcon} alt='globe' className='h-4 w-4' />
                    <img src={fbIcon} alt='globe' className='h-4 w-4' />
                </span>
            </div>
            <div className='flex justify-between'>
                <span className=''>Ratings</span>
                <img src={starIcon} alt='globe' className='h-5 w-5' />
            </div>
        </div>
    );

    return (
        <div className="bg-[#202F4C] p-4 rounded-2xl">
            <div className='flex justify-between items-center'>
                <div className="flex items-center gap-2 mb-4">
                    <img src={img} alt='btc' />
                    <div>
                        <h2 className="font-semibold">Bitcoin/USD</h2>
                        <p className="text-slate-400 text-sm">
                            Today up to <span className='text-[#37CBB0]'>+2.34%</span>
                        </p>
                    </div>
                </div>
                <img src={graph} alt='graph' className='h-10' />
            </div>


            <div className="space-y-2 flex flex-wrap md:block gap-2 md:gap-0 justify-between w-full">
                <StatItem label="Price" value={cryptoData.price} />
                <StatItem label="Volume" value={cryptoData.volume} />
                <StatItem label="Market Cap" value={cryptoData.marketCap} percentage="1.54%" />
                <StatItem label="FDV" value={cryptoData.fdv} />
                <StatItem label="Total Supply" value={cryptoData.totalSupply} isFull={isMobile} />

                <div className="space-y-2 flex flex-wrap md:block gap-2 md:gap-0 justify-between w-full">
                    <StatHighlight label="All Time High" value={cryptoData.allTimeHigh} isFull={isMobile} />
                    <StatHighlight label="All Time Low" value={cryptoData.allTimeLow} isFull={isMobile} />
                </div>

                <Rating rating={cryptoData.rating} />
            </div>
        </div>
    )
}

export default LeftBarMarketData