import { FaCog } from "react-icons/fa";

const UserInfoCard = ({ name, email }) => {
    return (
        <div className="bg-[#2D3A58] text-white rounded-2xl overflow-hidden">
            <div className="flex flex-col md:flex-row items-center gap-5 md:gap-4 p-8">
                <div className="w-32 h-32 bg-[#32CCCB] text-black rounded-xl flex 
                    items-center justify-center text-7xl font-bold uppercase mr-4">
                    {name?.charAt(0)}
                </div>
                <div className="flex flex-col gap-2 items-center md:items-start">
                    <h3 className="text-3xl font-bold capitalize">{name}</h3>
                    <p className="text-sm text-gray-400 lowercase">{email}</p>
                </div>
            </div>
        </div>
    );
};

export default UserInfoCard;