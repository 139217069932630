import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../../Helpers/buttonLoader';
import '../../Styles/AddUser.css'
import Cookies from 'js-cookie';
import { X } from 'lucide-react';
import { getFirstName, getUserName } from '../../services/common.utils';

const AddUser = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subscriptionPeriod, setsubscriptionPeriod] = useState('');
    const [openTrades, setOpenTrades] = useState('');
    const [balance, setBalance] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const userName = getUserName()

    const validateForm = () => {
        if (!name.trim()) {
            setErrorMessage('Full Name is required.');
            return false;
        }
        if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
            setErrorMessage('A valid Email Address is required.');
            return false;
        }
        if (!phoneNumber.trim() || !/^(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(phoneNumber)) {
            setErrorMessage('A valid U.S. phone number is required (e.g., 123-456-7890 or (123) 456-7890).');
            return false;
        }
        if (!subscriptionPeriod || subscriptionPeriod <= 0) {
            setErrorMessage('Initial Cycles should be a positive number.');
            return false;
        }
        if (!openTrades || openTrades < 0) {
            setErrorMessage('Open Trades should be 0 or a positive number.');
            return false;
        }
        if (!balance || balance <= 0) {
            setErrorMessage('Initial Balance should be a positive number.');
            return false;
        }
        return true;
    };


    const handleAddUser = () => {
        setErrorMessage('');

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        let token = Cookies.get('token');
        let headers = {
            'Authorization': `Bearer ${token}`
        }
        let apiRequest = {
            "email": email,
            "username": name,
            "phoneNumber": phoneNumber,
            "subscriptionPeriod": Number(subscriptionPeriod),
            "balance": Number(balance)
        }
        // console.log('$$$$$$$apiRequest', apiRequest)
        axios.post('https://backend.luxara.ai/api/v1/users', apiRequest, { headers: headers })
            .then((apiResponse) => {
                console.log('apiResponse', apiResponse.data);
                setLoading(false);
                navigate('/admin/home');
            })
            .catch((apiError) => {
                // console.log('apiError', apiError)
                // console.log('apiError', apiError.response.data.message);

                setErrorMessage(apiError?.response?.data?.message || 'Internal server error');
                setLoading(false);
                if (apiError.response.status === 401) {
                    navigate('/login')
                }
            })
    }

    return (
        <div className="h-full text-white md:p-6">
            {/* Header */}
            <h1 className="text-4xl font-light mb-12 text-center md:text-start">
                Hello <span className="text-[#3CC8C8] capitalize">{getFirstName()}</span>
            </h1>
            <div className="max-w-3xl mx-auto">
                {/* Form Card */}
                <div className="bg-[#212F4C] rounded-2xl shadow-lg">
                    <div className="p-8 flex justify-between items-center mb-8 border-b-2 border-b-[#152445]">
                        <h2 className="text-2xl font-medium">Add New User</h2>
                        <button className="text-[#3CC8C8] hover:text-white">
                            <X size={24} onClick={() => navigate('/admin/home')} />
                        </button>
                    </div>

                    {/* Form Fields */}
                    <div className="space-y-6 p-8 pt-0">
                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Full Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setErrorMessage('');
                                }}
                                placeholder="Full Name"
                                className="w-full bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Email Address</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrorMessage('');
                                }}
                                placeholder="Email address"
                                className="w-full bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Phone Number</label>
                            <div className="flex">
                                <select className="bg-[#1B2537] border border-gray-700 rounded-l-full px-3 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors">
                                    <option>+1</option>
                                </select>
                                <input
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="000 123 003"
                                    className="w-full bg-[#1B2537] border border-gray-700 rounded-r-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm text-[#3CC8C8] mb-2">Initial Cycles</label>
                                <input
                                    type="number"
                                    value={subscriptionPeriod}
                                    onChange={(e) => {
                                        setsubscriptionPeriod(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="15"
                                    className="w-full text-center bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                            </div>
                            <div>
                                <label className="block text-sm text-[#3CC8C8] mb-2">Open Trades</label>
                                <input
                                    type='number'
                                    value={openTrades}
                                    onChange={(e) => {
                                        setOpenTrades(e.target.value);
                                        setErrorMessage('');
                                    }
                                    }
                                    placeholder="3"
                                    className="w-full text-center bg-[#1B2537] border border-gray-700 rounded-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm text-[#3CC8C8] mb-2">Initial Balance</label>
                            <div className="flex rounded-full">
                                <select className="bg-[#1B2537] rounded-l-full border border-gray-700 px-3 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors">
                                    <option>$</option>
                                </select>
                                <input
                                    type="number"
                                    value={balance}
                                    onChange={(e) => {
                                        setBalance(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    placeholder="55000"
                                    className="w-full bg-[#1B2537] border border-gray-700 rounded-r-full px-4 py-3 focus:outline-none focus:border-[#3CC8C8] transition-colors"
                                />
                            </div>
                        </div>

                        {errorMessage && (
                            <div className="text-red-500 text-sm">{errorMessage}</div>
                        )}

                        {/* Add User Button */}
                        <button
                            onClick={handleAddUser}
                            disabled={loading}
                            style={{
                                background: 'linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%)'
                            }}
                            className="w-fit px-12 mx-auto text-white rounded-full py-4 font-medium hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
                        >
                            {loading ? (
                                <>
                                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                    <span>Please wait ...</span>
                                </>
                            ) : (
                                <>
                                    <span>+</span>
                                    <span>Add User</span>
                                </>
                            )}
                        </button>

                        {/* {errorMessage &&  <p className="text-red-500 mt-4">{errorMessage}</p>} */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser;